import React from 'react'
import axios from 'axios';
import { toast } from 'react-hot-toast';
import UserPreferences from "../components/settings/UserPreferences"

const DeleteProxy = ({ id , callback}) => {
  const headers = {

    'ngrok-skip-browser-warning': 'skip',
    'x-user-provided-key': UserPreferences.getServerPassword(),
    'Content-type': 'application/json'
  }
    const del = (identity,callback) => {
        console.log("here")
        axios
        .delete(UserPreferences.getServerUrl() + "/proxypools/" + identity, { headers: headers })
          .then((res) => {
            console.log(res);
            if (res.data === "error") {
    
              toast.error('Something went wrong', {
                position: 'bottom-center',
                style: {
                  background: "black",
                  color: "white",
                  border: "1px solid #1F2023",
                  borderRadius: 20,
                  autoClose: 3000,
                }
              });
            } else {
              callback()
              toast.success(res.data.msg, {
                position: 'bottom-center',
                style: {
                  background: "black",
                  color: "white",
                  border: "1px solid #1F2023",
                  borderRadius: 20,
                  autoClose: 3000,
                }
              });

            }
          })
          .catch((err) => toast.error(`Something went wrong due to ${err} `, {
            position: 'bottom-center',
            style: {
              background: "black",
              color: "white",
              border: "1px solid #1F2023",
              autoClose: 3000,
            }
          }));
      }
    return (
        <>
            <div className="modal fade" id={"Delete_Proxy_Modal" + id} tabIndex="-1" data-bs-backdrop="true" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm ">
                    <div className="modal-content">
                        <div className="modal-header p-3 pb-1">
                            {/* <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                Delete Profiles
              </h5> */}
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button> */}
                            <h5 className='text-light'>Are you sure you want to delete this proxypool ?</h5>

                        </div>
                        <div className='modal-body '>
                            <button className='btn btn-success btn-sm me-2 ' onClick={() => del(id,callback)} data-bs-dismiss="modal">YES</button>
                            <button className='btn btn-danger btn-sm  ' data-bs-dismiss="modal" aria-label="Close">NO</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteProxy