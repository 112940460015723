import React from 'react'
import { useEffect, useState } from 'react';
import axios from "axios"
import UserPreferences from "../components/settings/UserPreferences"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
  } from "recharts";
const MonthlyAnalyticGraph = (data) => {

    try{

        var Monthlydata = Object.entries(data.data?.spendings_last_seven_months).map(([name, spendings]) => ({ name, spendings }));
    }
    catch {
        var Monthlydata = [
        ];
    }


    const yValues = Monthlydata.map((entry) => entry.spendings);
    const minYValue = Math.min(...yValues);

    // linechart

    return (
        <>
            <div className="card rounded-3 p-1 border-0 bg-transparent">
                <h6 className=" text-light mb-0">Monthly Analytics</h6>
                <p className="mypfont text-secondary ">Overview your monthly charts</p>
                <div className="card rounded-3 bor1 padding-1 bg-transparent">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="mypfont text-secondary m-0">MONTHLY SPENDINGS</p>
                        <div className="d-flex gap-1 align-items-center ">
                        <i className={data.data?.spendings_variation >= 0 ? "fa fa-arrow-circle-up text-success ":"fa fa-arrow-circle-down text-primary-shade "}></i>
                            <p className={data.data?.spendings_variation >= 0 ? "text-success mt-3":"text-primary-shade mt-3"}>{data.data?.spendings_variation}%</p>
                        </div>
                    </div>

                    <h5 className="text-light mbg">{data.data.total_spendings} €</h5>
                    <ResponsiveContainer>
                        <LineChart data={Monthlydata}>

                            <defs>
                                <linearGradient
                                    id="gradientColor"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop offset="5%" stopColor="#E8743E" />
                                    <stop offset="95%" stopColor="#D06A81" />
                                </linearGradient>
                                <filter id="dropShadow">
                                    <feDropShadow
                                        dx={0}
                                        dy={8}
                                        stdDeviation={20}
                                        floodColor="#D06A81"
                                    />
                                </filter>
                            </defs>

                            <XAxis dataKey="name" axisLine={false} interval="preserveStartEnd" />
                            <YAxis
                                axisLine={false}
                                domain={[minYValue, "dataMax"]}
                                hide={true}
                            />
                            <Tooltip />
                            
                            <Line
                                type="monotone"
                                dataKey="spendings"
                                stroke="url(#gradientColor)"
                                strokeWidth={3}
                                dot={{
                                    stroke: "url(#gradientColor)",
                                    strokeWidth: 2,
                                    fill: "white",
                                    r: 5,
                                }}
                                // activeDot={{ r: 3 }}
                                filter="url(#dropShadow)"
                            />

                        </LineChart>
                    </ResponsiveContainer>

                </div>
            </div>
        </>
    )
}

export default MonthlyAnalyticGraph