import React from 'react'

function UpdateModal({ data, i, retailersList, handleInputs, handleSubmit }) {
  return (
    <>
      <div className="modal fade" id={"Update_Profile_Modal" + i.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-margin">
          <div className="modal-content">
            <div className="modal-header padding-15">
              <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                Update Profiles
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button>
            </div>
            <form className="modal-body padding-15" method='post' onSubmit={handleSubmit}  >

              <div className="flex margin-bottom-15">

                <div className="popup-right-selects width-50 right">
                  <label className="white font-size-13 font-weight-400"> Profile Name</label>
                  <input type="text" placeholder="Enter Profile Name" name='label' value={data.label} onChange={handleInputs} className="setting-input" />
                </div>
                <div className="popup-right-selects width-50 right">
                  <label className="white font-size-13 font-weight-400">Retailer</label>
                  <select className="setting-input" name='shop' value={data.shop} onChange={handleInputs}>
                    {retailersList.map((res, index) => (
                      <option key={index} className="option" value={res.label}>{res.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              {data.shop === "AMAZON" || data.shop === "PSG" || data.shop === "TWICKETS" ? (
                <>
                  <div className="pop_header"> <span>LOGIN</span> </div>
                  <div className="flex margin-bottom-15">
                    <div className="popup-right-selects width-50 right">
                      <label className="white font-size-13 font-weight-400">Email/Username</label>
                      <input type="text" placeholder="Enter Email" name='login' value={data.login} onChange={handleInputs} className="setting-input" />
                    </div>
                    <div className="popup-right-selects width-50 right">
                      <label className="white font-size-13 font-weight-400"> Password</label>
                      <input placeholder="Enter Password" name='password' type="password" value={data.password} onChange={handleInputs} className="setting-input" />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )
              }
              {data.shop === "MICROMANIA" || data.shop === "FNAC" ? (
                <>
                  <div className="pop_header"> <span>Payment infos</span> </div>
                  <div className="flex margin-bottom-15">
                    <div className="popup-right-selects width-100 right">
                      <label className="white font-size-13 font-weight-400">Credit/Debit Card Number</label>
                      <input type="text" placeholder="Enter Credit/Debit Card Number" value={data.card_number} name='card_number' onChange={handleInputs} className="setting-input" />
                    </div>
                  </div>
                  <div className="flex margin-bottom-15">
                    <div className="popup-right-selects width-25">
                      <input type="text" placeholder="Exp. Month (MM)" value={data.card_expiration_month} name='card_expiration_month' onChange={handleInputs} className="setting-input" />
                    </div>
                    <div className="popup-right-selects width-25">
                      <input type="text" placeholder="Exp. Date (YYYY)" value={data.card_expiration_year} name='card_expiration_year' onChange={handleInputs} className="setting-input" />
                    </div>
                    <div className="popup-right-selects width-20">
                      <input type="text" placeholder="CVV" value={data.card_cvv} name='card_cvv' onChange={handleInputs} className="setting-input" />
                    </div>
                    <div className="popup-right-selects width-25">
                      <input type="text" placeholder="Card Owner" name='card_owner' value={data.card_owner} onChange={handleInputs} className="setting-input" />
                    </div>
                  </div>
                </>
              ) : (<></>)
              }

              <div className="pop_header"> <span>NOTIFICATIONS</span> </div>
              <div className="margin-bottom-15">
                <div className="popup-left-selects">
                  <label className="white font-size-13 font-weight-400"> Notification Webhook</label>
                  <input type="text" placeholder="Enter URL Webhook" name='webhook' value={data.webhook} onChange={handleInputs} className="setting-input" />
                </div>
              </div>
              <div className="margin-bottom-15 margin-top-30 text-left">
                <button type='submit' data-bs-dismiss="modal" className="active-btn">
                  Update Profile <img src={require('../assets/images/plus.png')} /></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateModal

{/* <div className="pop_header"> <span>PERSONAL INFORMATION</span> </div>
<div className="flex margin-bottom-15">
  <div className="popup-right-selects width-50 right">
    <label className="white font-size-13 font-weight-400">First Name</label>
    <input type="text" placeholder="Enter First Name" value={data.first_name} name='first_name' onChange={handleInputs} className="setting-input" />
  </div>
  <div className="popup-right-selects width-50 right">
    <label className="white font-size-13 font-weight-400"> Last Name</label>
    <input type="text" placeholder="Enter Last Name" value={data.last_name} name='last_name' onChange={handleInputs} className="setting-input" />
  </div>
</div>
<div className="flex margin-bottom-15">
  <div className="popup-right-selects width-50 right">
    <label className="white font-size-13 font-weight-400">Phone</label>
    <input type="text" placeholder="Enter Phone Number" value={data.push_phone} name='push_phone' onChange={handleInputs} className="setting-input" />
  </div>
  <div className="popup-right-selects width-50 right">
    <label className="white font-size-13 font-weight-400"> Postal Code</label>
    <input type="text" placeholder="Enter Postal Code" value={data.postal_code} name='postal_code' onChange={handleInputs} className="setting-input" />
  </div>
</div>
<div className="margin-bottom-15">
  <div className="popup-left-selects">
    <label className="white font-size-13 font-weight-400"> Street Address</label>
    <input type="text" placeholder="Enter Street Address" name='street_address' value={data.street_address} onChange={handleInputs} className="setting-input" />
  </div>
</div>
<div className="flex margin-bottom-15">
  <div className="popup-right-selects width-50 right">
    <label className="white font-size-13 font-weight-400">City</label>
    <input type="text" placeholder="Enter City" value={data.city} name='city' onChange={handleInputs} className="setting-input" />
  </div>
  <div className="popup-right-selects width-50 right">
    <label className="white font-size-13 font-weight-400"> Country</label>
    <input type="text" placeholder="Enter Country" value={data.country} name='country' onChange={handleInputs} className="setting-input" />
  </div>
</div>
<div className="pop_header"> <span>PAYMENT</span> </div>
<div className="flex margin-bottom-15">
  <div className="popup-right-selects width-50">
    <label className="white font-size-13 font-weight-600"> Card Owner</label>
    <input type="text" placeholder="Enter Card Owner Name" name='card_owner' value={data.card_owner} onChange={handleInputs} className="setting-input" />
  </div>
  <div className="popup-right-selects width-25">
    <label className="white font-size-13 font-weight-600">Exp. Month</label>
    <input type="text" placeholder="Enter Month (MM)" value={data.card_expiration_month} name='card_expiration_month' onChange={handleInputs} className="setting-input" />
  </div>
  <div className="popup-right-selects width-25">
    <label className="white font-size-13 font-weight-600">Exp. Date</label>
    <input type="text" placeholder="Enter Date (YY)" value={data.card_expiration_year} name='card_expiration_year' onChange={handleInputs} className="setting-input" />
  </div>
</div>
<div className="flex margin-bottom-15">
  <div className="popup-right-selects width-75 right">
    <label className="white font-size-13 font-weight-400">Credit/Debit Card Number</label>
    <input type="text" placeholder="Enter Credit/Debit Card Number" value={data.card_number} name='card_number' onChange={handleInputs} className="setting-input" />
  </div>
  <div className="popup-right-selects width-25 right">
    <label className="white font-size-13 font-weight-400"> CVV</label>
    <input type="text" placeholder="Enter CVV" value={data.card_cvv} name='card_cvv' onChange={handleInputs} className="setting-input" />
  </div>
</div> */}