import React from 'react';
import '../css/dashboard.css';
import LeftMenu from '../components/common/LeftMenu';
import CheckoutGraph from "../components/CheckoutGraph"
import QueueGraph from '../components/QueueGraph';
import MonthlyAnalyticGraph from '../components/MonthlyAnalyticGraph';
import axios from "axios"
import Header from '../components/common/Header';
import { useEffect, useState } from 'react';
import UserPreferences from "../components/settings/UserPreferences"
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import { toastError, toastSuccess } from '../components/common/toastUtils';

function formaterTimestamp(timestamp) {
  const dateObject = new Date(timestamp);

  const jour = dateObject.getDate();
  const mois = dateObject.getMonth() + 1;
  const heure = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const secondes = dateObject.getSeconds();
  const ampm = heure >= 12 ? "PM" : "AM";

  const dateFormatee = `${jour}/${mois < 10 ? "0" : ""}${mois} - ${heure % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes}:${secondes < 10 ? "0" : ""}${secondes} ${ampm}`;

  return dateFormatee;
}


function getRetailerImage(retailer) {
  if (retailer === "Amazon") {
    return require("../assets/logo_amazon.png")
  }
  if (retailer === "Ticketmaster FR") {
    return require("../assets/ticketmaster.png")
  }
  if (retailer === "Ticketmaster EU") {
    return require("../assets/ticketmaster.png")
  }
  if (retailer === "Ticketmaster") {
    return require("../assets/ticketmaster.png")
  }
  if (retailer === "Fnac") {
    return ""
  }
  if (retailer === "Micromania") {
    return require("../assets/m.png")
  }
  if (retailer === "Queue-IT") {
    return require("../assets/qt.png")
  }
  if (retailer === "Wetix") {
    return require("../assets/wet.png")
  }
  if (retailer === "Weezevent") {
    return require("../assets/we.png")
  }
  if (retailer === "Twickets") {
    return require("../assets/twickets.png")
  }
}

export default function Home() {
  const [checkouts, setCheckouts] = useState([]);
  const [filteredCheckouts, setFilteredCheckouts] = useState([]);
  const [isEmptyCheckouts, setIsEmptyCheckouts] = useState(false);
  const [isEmptyCheckoutsQueues, setIsEmptyCheckoutsQueues] = useState(false);
  const [checkoutsRetrivalError, setcheckoutsRetrivalError] = useState(true);
  const [checkoutsQueues, setCheckoutsQueues] = useState([]);
  const [stats, setStats] = useState({});
  const headers = {
    'ngrok-skip-browser-warning': 'skip',
    'x-user-provided-key': UserPreferences.getServerPassword(),
    'Content-type': 'application/json'
  }
  const [selectedCheckout, setSelectedCheckout] = useState(null);

  const displayCheckoutDetails = (ligne) => {
    setSelectedCheckout(ligne);
  };

  const handleSearchCheckoutsInput = (event) => {
    var search_pattern = event.target.value
    if (search_pattern.length >= 2){
      let filteredCheckouts = checkouts.filter(checkout => {
        return checkout.retailer.toLowerCase().includes(search_pattern.toLowerCase()) || checkout.title.toLowerCase().includes(search_pattern.toLowerCase()) || checkout.location?.toLowerCase().includes(search_pattern.toLowerCase());
      });
      setFilteredCheckouts(filteredCheckouts)
    }
    else {
      setFilteredCheckouts(checkouts)
    }
  }
  function refreshCheckouts(){
    getCheckouts();
    toastSuccess("Checkouts refresh successfull")
  }

  function getCheckouts() {
    axios
      .get(UserPreferences.getServerUrl() + "/checkouts", { headers: headers })
      .then((res) => {
        setcheckoutsRetrivalError(false)
        setCheckouts(res.data.result[0])
        setFilteredCheckouts(res.data.result[0])
        setCheckoutsQueues(res.data.result[1])
        if (res.data.result[0].length === 0) {
          setIsEmptyCheckouts(true)
        }
        else {
          setIsEmptyCheckouts(false)
        }
        if (res.data.result[1].length === 0) {
          setIsEmptyCheckoutsQueues(true)
        }
        else {
          setIsEmptyCheckoutsQueues(false)
        }
      })
      .catch((e) => {
        setcheckoutsRetrivalError(true)
        return console.log(`fetching issue due to ${e}`);
      });
  }
  function getStats() {
    axios
      .get(UserPreferences.getServerUrl() + "/stats", { headers: headers })
      .then((res) => {
        setStats(res.data.result)
      })
      .catch((e) => {
        return console.log(`fetching issue due to ${e}`);
      });
  }


  useEffect(() => {
    getCheckouts();
    getStats();
    if (UserPreferences.getServerPassword() === null) {
      var maModal = new bootstrap.Modal(document.getElementById('getstart'));
      maModal.show();
    }
  }, []);





  return (
    <div className="main-container">
      <LeftMenu />
      <div className="right-side">
        <Header />
        <div className="middle-section-container padding-top0 flex">
          <div className="second_section_graph">
            <div className="checkout_graph">
              <CheckoutGraph data={stats} />

            </div>
            <div className="queue_passedgraph">
              <QueueGraph data={stats} />
            </div>
            <div className="monthly_analytic">

              <MonthlyAnalyticGraph data={stats} />
            </div>
          </div>
          <div className="slider_section">
            <div className="col-lg-12 padding-left-right-0">
              <div className="slider-bg margin-top-12 border-radius-5">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-bs-ride="carousel" data-interval="100">
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"></button>
                    {/* <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="2"
                      aria-label="Slide 3"></button> */}
                  </div>

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="slider-content-wraper flex">
                        <div className="slider-text-left">
                          <p> <span>Learn More</span> </p>
                          <h4>{' '}New Beta secret module incoming !! </h4>
                        </div>
                        {/* <div className="slider-right-shoe">
                          <img src={require('../assets/newmodule.png')} />
                        </div> */}
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="slider-content-wraper flex">
                        <div className="slider-text-left">
                          <p> <span>Learn More</span> </p>
                          <h4>{' '}Get ready for two new Amazon regions</h4>
                          <p>
                            New, now Netherland and Belgium are supported !{' '}
                          </p>
                        </div>
                        <div className="slider-right-shoe">
                          <img src={require('../assets/logs.png')} />
                        </div>
                      </div>
                    </div>

                    {/* <div className="carousel-item">
                      <div className="slider-content-wraper flex">
                        <div className="slider-text-left">
                          <p> <span>Learn More</span> </p>
                          <h4>{' '}Now supporting Amazon<br /> DE & AT </h4>
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Malesuada leo ma eleifend amet.{' '}
                          </p>
                        </div>
                        <div className="slider-right-shoe">
                          <img src={require('../assets/logs.png')} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="quick_links">
              <div className="links">
                <a href="#" className="active"> Quick Links</a>
                <a href="https://discord.com/channels/949003933001605150/949323453041872907" target='_blank'> Changelogs</a>
                {/* <a href="#"> Option 3 </a> */}
              </div>
              <div className="link_boxs"> 
                <a style={{textDecoration: "none"}} target="_blank" href="https://twitter.com/agorai0">
                <div className="lbx1 guide">
                  {' '} <img src={require('../assets/twitter_logo.png')} />{' '}
                  <p> Twitter </p>
                </div>
                </a>

                <a style={{textDecoration: "none"}} target="_blank" href="https://discord.com/channels/949003933001605150/968568252903071754">
                <div className="lbx1 discord">
                  {' '} <img src={require('../assets/discords.png')} />{' '}
                  <p> Support </p>
                </div>
                </a>

                <a style={{textDecoration: "none"}} target="_blank" href="https://whop.com/orders/products/">
                <div className="lbx1 whop">
                  {' '} <img src={require('../assets/whops.png')} />{' '}
                  <p> Whop </p>
                </div>
                </a>
                <a style={{textDecoration: "none"}} target="_blank" href="https://apps.apple.com/fr/app/agoraio/id1621323033">
                <div className="lbx1 whop">
                  {' '} <img src={require('../assets/apple_logo.png')} />{' '}
                  <p> Apple </p>
                </div>
                </a>
                <a style={{textDecoration: "none"}} target="_blank" href="https://play.google.com/store/apps/details?id=com.devstored.agoraioapp">
                <div className="lbx1 whop">
                  {' '} <img src={require('../assets/google_logo.png')} />{' '}
                  <p> PlayStore </p>
                </div>
                </a>
{/* 
                <a style={{textDecoration: "none"}} target="_blank" href="https://x.com/agorai0">
                <div className="lbx1 export">
                  {' '} <img src={require('../assets/twitter-x.png')} />{' '}
                  <p> Twitter - X </p>
                </div>
                </a> */}

                {/* <div className="lbx1 import">
                  {' '} <img src={require('../assets/imports.png')} />{' '}
                  <p> Import </p>
                </div> */}
              </div>
            </div>


            <div className="latest_qu">
              <div className="connection_right">
                <h5> Latest Queues Passed </h5>
              </div>
              <div className="checkout_container">
                {checkoutsQueues?.map((res, index) => (
                  <div className="checkout_boxes">
                    <div className="flex">
                      <div className="text-center check_product">
                        {' '} <img src={getRetailerImage(res.retailer)} />{' '}
                      </div>
                      <div className="check_right">
                        <h5> Queue passed successfully! </h5>
                        <ul className="flex adjust-header">
                          <li>  {res.retailer} - {res.title}   </li>
                          <li> {formaterTimestamp(res.timestamp)}  </li>
                        </ul>
                      </div>
                    </div>
                    <ul className="checkList">
                      {/* <li><span>Waiting Time: </span>3m 34s </li> */}
                      <li>Task ID: {res.id} </li>
                      <li className="waiting"><a style={{textDecoration: "none" ,color:"white"}} href={res.checkout_url} target="_blank">Queue pass </a> </li>
                    </ul>
                  </div>

                ))}
                {isEmptyCheckoutsQueues && (
                  <div className="checkout_empty">
                    <div className="No_data">
                      {' '} <img src={require('../assets/no-checkout.png')} />{' '}
                      <h2> You have no Queues Passed yet</h2>
                      <p> Create some tasks to get started! </p>
                    </div>
                  </div>
                )}
                {checkoutsRetrivalError && (
                  <div className="checkout_error No_data">
                    {' '} <img src={require('../assets/error-checkout.png')} />{' '}
                    <h2> There was an error retrieving <br />your checkouts</h2>
                    <p> Make sure to connect your server properly </p>
                    {/* <a href="#">Reload</a> */}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="right-bar">
            <div className="connection_right">
              <h5 onClick={refreshCheckouts}> Latest Checkouts <img src={require('../assets/refres.png')} /> </h5>
             
            </div>
            <div className="checkouts_list">
              <div className="checkout_inputs">
                <div className="check_inp">
                  <input type="text" placeholder="Search for checkouts" onChange={handleSearchCheckoutsInput} />
                </div>
                {/* <div className="filters">
                  <button>
                    <img src={require('../assets/filters.png')} /> Filter </button>
                </div> */}
              </div>
              {filteredCheckouts?.map((res, index) => (
                <div className="checkout_boxes">
                  <div className="flex">
                    <div className="text-center check_product">
                      {' '} <img src={res.url_image} />{' '}
                    </div>
                    <div className="check_right">
                      <h5> {res.title} </h5>
                      <ul className="flex adjust-header">
                        <li>  {res.retailer} {res.location != "" && res.retailer == "Amazon" ? "- " + res.location.toUpperCase() : ""} </li>
                        <li> {formaterTimestamp(res.timestamp)}  </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="checkList">
                    <li>{parseFloat(res.price).toFixed(2) !== "NaN" ? parseFloat(res.price).toFixed(2) : "?" } € </li>
                    <li> {res.profile.substring(0, 10)}... </li>
                    { res.checkout_url && (
                      <li className="waiting"><a href={res.checkout_url} target="_blank" style={{textDecoration: "none" ,color:"white"}} >Checkout </a> </li>

                    )}
                    <li className="active" data-bs-toggle="modal" data-bs-target="#checkout_details" onClick={() => displayCheckoutDetails(res)}> Details </li>
                  </ul>
                </div>
              ))}
              {isEmptyCheckouts && (
                <div className="checkout_empty ">
                  <div className="No_data">
                    {' '} <img src={require('../assets/no-checkout.png')} />{' '}
                    <h2> You have no checkouts yet</h2>
                    <p> Create some tasks to get started! </p>
                  </div>
                </div>
              )}



            </div>


            {checkoutsRetrivalError && (
              <div className="checkout_error No_data">
                {' '} <img src={require('../assets/error-checkout.png')} />{' '}
                <h2> There was an error retrieving <br />your checkouts</h2>
                <p> Make sure to connect your server properly </p>
                {/* <a href="#">Reload</a> */}
              </div>
            )}


          </div>
        </div>
      </div>
      <div className="modal fade" id="checkout_details" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-margin">
          {selectedCheckout && (
            <div className="modal-content">
              <div className="modal-header padding-15 flex">
                <div className="nba_left">
                  {' '} <img src={selectedCheckout.url_image} />{' '}
                </div>
                <div className="nba_text">
                  <h3> {selectedCheckout.title}</h3>
                  <p> <span> {formaterTimestamp(selectedCheckout.timestamp)}</span> </p>
                </div>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button>
              </div>
              <div className="modal-body padding-15">
                <div className="flex margin-bottom-15 success_contain">
                  <div className="success_check">
                    <h3> Successful Checkout!</h3>
                    <p> Task ID: {selectedCheckout.id} </p>
                  </div>
                  <div className="ticket_mas">
                    <div className="ticket_Masbtn">
                      {' '} <img src={getRetailerImage(selectedCheckout.retailer)} />{' '}
                    </div>
                  </div>
                </div>

                <div className="flex margin-bottom-15 check_outs">
                  <div className="chekPop_left">
                    <p> {' '} <img src={require('../assets/user.png')} />{' '} Profile </p>
                  </div>
                  <div className="chekPopright">
                    <p><span>{selectedCheckout.profile}</span> </p>
                  </div>
                </div>

                <div className="flex margin-bottom-15 check_outs">
                  <div className="chekPop_left">
                    <p> {' '} <img src={require('../assets/location.png')} />{' '} Lieu </p>
                  </div>
                  <div className="chekPopright">
                    <p><span>{selectedCheckout.location}</span> </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="width_50 margin-right-10">
                    <div className="flex margin-bottom-15 check_outs">
                      <div className="chekPop_left">
                        <p> {' '} <img src={require('../assets/bag.png')} />{' '} Price </p>
                      </div>
                      <div className="chekPopright">
                        <p><span className="light_blue">{ parseFloat(selectedCheckout.price).toFixed(2) } € </span> </p>
                      </div>
                    </div>
                  </div>
                  <div className="width_50">
                    <div className="flex margin-bottom-15 check_outs">
                      <div className="chekPop_left">
                        <p> {' '} <img src={require('../assets/paper.png')} />{' '} Quantity </p>
                      </div>
                      <div className="chekPopright">
                        <p><span>{selectedCheckout.quantity} </span> </p>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedCheckout.category !== "" && (
                  <div className="flex margin-bottom-15 check_outs">
                    <div className="chekPop_left">
                      <p> {' '} <img src={require('../assets/category.png')} />{' '} Category </p>
                    </div>
                    <div className="chekPopright">
                      <p><span>{selectedCheckout.category}</span> </p>
                    </div>
                  </div>
                )}
                {selectedCheckout.tarif !== "" && (
                  <div className="flex margin-bottom-15 check_outs">
                    <div className="chekPop_left">
                      <p> {' '} <img src={require('../assets/ticket.png')} />{' '} Tarif </p>
                    </div>
                    <div className="chekPopright">
                      <p><span> Tarif Normal</span> </p>
                    </div>
                  </div>
                )}

                {selectedCheckout.checkout_url !== "" && (
                  <div className="flex margin-bottom-15 check_outs" style={{cursor:"pointer"}} onClick={() => {window.open(selectedCheckout.checkout_url, "_blank");}}>
                    <div className="chekPop_left wid30">
                      <p> {' '} <img src={require('../assets/scan.png')} />{' '} Link </p>
                    </div>
                    <div className="chekPopright wid80">
                      <p><span> {selectedCheckout.checkout_url.substring(0, 35)} </span> </p>
                      {' '} <img src={require('../assets/scan.png')} />{' '}
                    </div>
                  </div>
                )}

              </div>
            </div>

          )}
        </div>
      </div>
      <div show="true" className="modal fade" id="getstart" tabIndex="-1" aria-labelledby="mymodal" aria-hidden="true">
        <div className="modal-dialog modal-margin">
          {true && (
            <div className="modal-content">
              <div className="modal-header padding-15 log_head">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button>
              </div>
              <div className="modal-body padding-15">
                <div className="login-section margin-bottom-15 text-center">
                  <img src={require('../assets/logo.png')} />
                  <h5>Welcome to AgorAIO!</h5>
                  <p>Buyer assistant for marketplace !</p>
                  <button className="getstardbtn"> <a href="#"> Get Started</a></button>
                  <h4> Set your password server to start </h4>
                  <img src={require('../assets/pass.png')} className="pass_img" />
                  <button className="gotosetting" href="settings"> <a href="settings"> Go to Settings</a></button>
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>

  );
}
