import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthService, { KEY_URL_REDIRECTION } from "./authentication/AuthService";

const PrivateRoute = () => {
    if (!AuthService.isAuthenticated()){
      return <Navigate to="/login" />;
    }
    else {
      return <Outlet />
    }
   
}

export default PrivateRoute