import AuthService from "../authentication/AuthService";


async function Callback() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (AuthService.isConnecting() === null || AuthService.isConnecting() === "false") {
      AuthService.setConnecting("true");
      const resp = await fetch(' https://api.whop.com/api/v5/oauth/token', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'client_id': 'ZP6jm2quHjnpbxNteEZaCDpIazdyc98W4ZdZr4aAEic',
          'client_secret': 'UESvsvYHRcj7OE9RRDMzQ-7STKzNi7DunNI5kgM3LFA',
          'response_type': 'code',
          'grant_type': 'authorization_code',
          'redirect_uri': AuthService.getCallbackUrl(),
          'code': code
        })
      });
      const json = await resp.json();
      if (json["access_token"] !== null) {
        const token = json["access_token"];
        AuthService.setToken(token);
        var page = 1;
        let memberships_array = [];
        while (true) {
          const memberships = await fetch('https://api.whop.com/api/v2/me/memberships?page=' + page, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          });
          const json_mem = await memberships.json();
          if (json_mem.data.length === 0) {
            break;
          } else {
            memberships_array = memberships_array.concat(json_mem.data);
            page += 1;
          }
        }
        for (var membership of memberships_array) {
          if (membership["valid"] === true) {
            if (membership["product"] !== "prod_go4s4QTI2SrXO") {
              AuthService.setLicense(membership["license_key"]);
            }
          }
        }
      }
    }  
}

export default Callback;
