import React from 'react'
import { useEffect, useState } from 'react';
import axios from "axios"
import UserPreferences from "../components/settings/UserPreferences"
import {
   
    BarChart,
   
    XAxis,
    Cell,
    Tooltip,
    
    ResponsiveContainer,
    Bar,
  } from "recharts";
import userEvent from '@testing-library/user-event';

  const barColors = ["#363546", "#82ca9d", "#363546", "#82ca9d"]

const CheckoutGraph = (data) => {
    try{

        var CheckoutData = Object.entries(data.data?.queues_last_three_months).map(([date, volume]) => ({ date, volume }));
    }
    catch {
        var CheckoutData = [
        ];
    }




    return (
        <>
            <div className="card rounded-3 p-1 border-0 bg-transparent ">
                {/* **********head************** */}

                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-2 align-items-center">
                        <button className="btn-sm btn btn-secondary ">
                            Monthly
                        </button>
                        {/* <span className="text-secondary">Monthly</span> */}
                    </div>
                </div>

                {/* **********head************** */}

                <div className="row  align-items-center">
                    <div className="col-md-6">
                        <p className="graph_h2">TOTAL CHECKOUTS</p>


                        <div className="align-items-center">

                          <span className="graph_h1">{data.data?.total_checkouts}</span>

                          <span className="me-2" > <i className={data.data?.checkouts_variation >= 0 ? "fa fa-arrow-circle-up text-success ":"fa fa-arrow-circle-down text-primary-shade "}></i></span>
                          <span className={data.data?.checkouts_variation >= 0 ? "text-success ":"text-primary-shade "}>{data.data?.checkouts_variation}%</span>
 
                        </div>

                        <span className="text-secondary">Compared to last month</span>
                    </div>
                    <div className="col-md-6">
                        <ResponsiveContainer width="100%" height={200}>
                            <BarChart data={CheckoutData}>
                                <XAxis dataKey="date" axisLine={false} style={{fontSize:"18px"}} />

                                <Tooltip cursor={{fill: 'transparent'}} />

                                {/* <Line
                                    type="monotone"
                                    dataKey="price"
                                    stroke="#E8743E"
                                    strokeWidth={2}
                                    yAxisId="left"
                                    dot={false}
                                /> */}
                                <Bar
                                    dataKey="volume"
                                    fill="#82ca9d"
                                    yAxisId="right"
                                    barSize={30}
                                    radius={10}
                                    >
                                        {
                                        barColors.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                                        ))
                                    }
                                </Bar>
                                
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckoutGraph