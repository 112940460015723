import React from 'react'

function ViewModal({i}) {
  return (
    <>
      <div className="modal fade" id={"View_Profile_Modal" + i.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-margin">
          <div className="modal-content">
            <div className="modal-header padding-15">
              <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                View Detail
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button>
            </div>
            <div className='modal-body'>
              <div className='table-responsive'>
                <table>
                  <tr>
                    <th>Profile Name</th>
                    <td>{i.label}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{i.login}</td>
                  </tr>
                  <tr>
                    <th>Shop</th>
                    <td>{i.shop}</td>
                  </tr>
                  <tr>
                    <th>Card Owner</th>
                    <td>{i.card_owner}</td>
                  </tr>
                  <tr>
                    <th>Card Number</th>
                    <td>{i.card_number}</td>
                  </tr>
                  <tr>
                    <th>Card Expiry</th>
                    <td>{(i.card_expiration_month) + '/' + (i.card_expiration_year)} </td>
                  </tr>
                  <tr>
                    <th>Web Hook</th>
                    <td>{(i.card_expiration_month) + '/' + (i.card_expiration_year)} </td>
                  </tr>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div></>
  )
}

export default ViewModal