export const KEY_URL_REDIRECTION = "urlBeforeAuthRedirection";

class AuthService {
  KEY_LICENSE = "license";

  setToken(token) {
    localStorage.setItem("access_token", token);
  }

  isConnecting() {
    return localStorage.getItem("is_connecting");
  }

  setConnecting(flag) {
    localStorage.setItem("is_connecting", flag);
  }

  getToken() {
    return localStorage.getItem("access_token");
  }

  setLicense(license) {
    localStorage.setItem(this.KEY_LICENSE, license);
  }

  getLicense() {
    return localStorage.getItem(this.KEY_LICENSE);
  }

  isAuthenticated() {
    return Boolean(localStorage.getItem(this.KEY_LICENSE));
  }

  getCallbackUrl() {
    return window.location.origin + "/callback";
  }

  getLastUserUrl() {
    const url = localStorage.getItem(KEY_URL_REDIRECTION);
    return url || "/";
  }
}

export default new AuthService();
