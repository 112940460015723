import React, { useState } from 'react';
import '../css/login.css';
import axios from "axios"
import toast from 'react-hot-toast';

export default function Login() {
    const [licenseKey, setLicenseKey] = useState('');
    const [showMessage, setShowMessage] = useState('');
    const [loading, setLoading] = useState(false);
    // localStorage.clear()
    const handleStoreAndFetch = async () => {
        if (!licenseKey.trim()) {
            setShowMessage('Please enter a license key.');
            return;
        }
        console.log(licenseKey)
        // Store license key in localStorage
        await localStorage.setItem('license', licenseKey);

        setLoading(true);
        setShowMessage('Fetching data...');

        var headers = {
            "Content-Type": "application/json",
            "front-api-key": "Bearer ZGEG"
        }
        axios
            .get("https://r2ebd2n2rlt5fgfkmdoqs6hpga0mdtfw.lambda-url.eu-west-1.on.aws/memberships/" + licenseKey, { headers: headers })
            .then((res) => {
                const urlToRedirect = "/dashboard";
                console.log(res)
                console.log("heeeere")
                window.location.href = urlToRedirect;
            })
            .catch((e) => {
                setLoading(false);
                toast.error(`License unknown or incorrect`, {
                    position: 'bottom-center',
                    style: {
                      background: "black",
                      color: "white",
                      border: "1px solid #1F2023",
                      autoClose: 3000,
                    }
                  });
                return console.log(`fetching issue due to ${e}`);
            });
    };

    return (
        <div className="login-body">
            <div className="login-container flex">
                <div className="left-login">
                    <div className="login-section margin-bottom-15">
                        <h5>Welcome to AgorAIO!</h5>
                        <div className="log_sec">
                            <div className="logo-login">
                                <img src={require('../assets/logo.png')} alt="AgorAIO Logo" />
                                <span> AgorAIO </span>
                            </div>
                            <div className="intro_log">
                                <div className="flex">
                                    <div className="left_intro">
                                        <h4> <span>Introducing AgorAIO </span> </h4>
                                        <h3> Your favourite <br />retailers - all in one <br />application</h3>
                                    </div>
                                    <div className="right_intro">
                                        <img src={require('../assets/logs.png')} className="login_intro" alt="Intro" />
                                    </div>
                                </div>
                            </div>

                            <div className="login_display">
                                {
                                    !loading ? (
                                        <>

                                            <div className="login-left-text margin-bottom-15">
                                                <h4>ENTER LICENSE KEY</h4>
                                            </div>
                                            <div className="popup-right-selects width-100">
                                                <input
                                                    type="text"
                                                    className="setting-input"
                                                    style={{ "paddingBottom": "10px" }}
                                                    value={licenseKey}
                                                    onChange={(e) => setLicenseKey(e.target.value)}
                                                    placeholder="Enter License Key"
                                                />
                                                <button onClick={handleStoreAndFetch} className="active-btn">

                                                    {/* <img src={require('../assets/logo.png')} alt="Sign In" /> */}
                                                    Submit
                                                </button>
                                                <br /><br />
                                                {showMessage && <p>{showMessage}</p>}
                                                <p style={{ color: 'white', fontSize: 'x-small' }}>
                                                    WARNING: The use of this software is at your own risk.
                                                    The author cannot be held responsible for any consequences arising from the use of this software.
                                                    By proceeding, you agree to release the author from any legal liability.
                                                </p>
                                                <p>Copyright AgorAIO 2023 © All Rights Reserved</p>
                                            </div>
                                        </>
                                    ) : <>
                                        <div className="login-left-text margin-bottom-15">
                                            <h4>ENTER LICENSE KEY</h4>
                                        </div>
                                        <div className="popup-right-selects width-100">
                                            <div className="login-left-text margin-bottom-15 text-center">
                                                <h4>Waiting for Authorization</h4>
                                            </div>
                                            {showMessage && <p>{showMessage}</p>}
                                            <p style={{ color: 'white', fontSize: 'x-small' }}>
                                                WARNING: The use of this software is at your own risk.
                                                The author cannot be held responsible for any consequences arising from the use of this software.
                                                By proceeding, you agree to release the author from any legal liability.
                                            </p>
                                            <p>Copyright AgorAIO 2023 © All Rights Reserved</p>
                                        </div>

                                    </>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
