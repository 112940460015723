import React from 'react'

const Class = () => {

    const apple = () => {

    }
    return (
        <>
            <h1 className="text-light my-4 text-center">Hello I am </h1>
            <div className="text-center">
                <button className="btn btn-light" onClick={() => apple()}>Click Me</button>
            </div>

            <div className='row justify-content-center'>
                <div className='col-md-3'>
                    <form className='p-3 card border bg-dark rounded-3 my-5 '>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label text-light">name</label>
                            <input type="text" className="form-control bg-dark border" id="exampleFormControlInput1" placeholder="Enter Name" />
                        </div>
                        <select className="form-select mb-3 bg-dark border text-light" aria-label="Default select example">z
                            <option selected >Select Your Choice</option>
                            <option value="Marvel">Marvel</option>
                            <option value="DC">DC</option>
                        </select>
                        <div className="form-check mb-3">
                            <input className="form-check-input bg-dark borderd" type="checkbox" defaultValue id="flexCheckDefault" />
                            <label className="form-check-label text-light" htmlFor="flexCheckDefault">
                                Accept Terms and Conditions
                            </label>
                        </div>
                        
                            <button type='submit' className='btn btn-danger w-100'>Submit</button>
                        



                    </form>
                </div>
            </div>
        </>
    )
}

export default Class