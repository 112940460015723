export const country_id = [
  { label: "Amazon France", value: "fr" },
  { label: "Amazon UK", value: "co.uk" },
  { label: "Amazon Germany", value: "de" },
  { label: "Amazon Spain", value: "es" },
  { label: "Amazon Japan", value: "co.jp" },
  { label: "Amazon Italy", value: "it" },
  { label: "Amazon Belgium", value: "com.be" },
  { label: "Amazon Netherland", value: "nl" },
];
export const cloudflare_challenge = [
  { label: "Local", value: "LOCAL" },
  { label: "Remote", value: "REMOTE" },
  // { label: "Experimental", value: "EXPERIMENTAL" },
];

export const aco_method = [
  { label: "MONITORING + TURBO", value: "METHOD1" },
  { label: "MONITORING + CLASSIC", value: "METHOD2" },
  { label: "TURBO", value: "METHOD3" },
  { label: "CLASSIC", value: "METHOD4" },
]
export const method = [
  { label: "Amazon only", value: "AMAZON_ONLY" },
  { label: "Amazon only + Price limit", value: "AMAZON_ONLY_PRICE_LIMIT" },
  { label: "FBA + Price limit ", value: "FBA_PRICE_LIMIT" },
  { label: "FBM + Price limit ", value: "FBM_PRICE_LIMIT" },
]

const ShopType = {
  ALL: "ALL",
  AMAZON: "AMAZON",
  TICKETMASTER: "TICKETMASTER",
  TICKETMASTER_EU: "TICKETMASTER_EU",
  MICROMANIA: "MICROMANIA",
  QUEUEIT: "QUEUEIT",
  WEEZEVENT: "WEEZEVENT",
  WETIX: "WETIX",
  FNAC: "FNAC",
  TWICKETS: "TWICKETS",
};

export const retailersList = [
  { value: ShopType.AMAZON, name: "Amazon", label: "AMAZON", isAmazon: true, img: "amazon.png" },
  { value: ShopType.TICKETMASTER, name: "Ticketmaster France", label: "TICKETMASTER", isLDLC: true, img: "tinder.png" },
  { value: ShopType.TICKETMASTER_EU, name: "Ticketmaster Europe", label: "TICKETMASTER_EU", isLDLC: true, img: "tinder.png" },
  { value: ShopType.QUEUEIT, name: "Queue-it", label: "QUEUEIT", isLDLC: true, img: "qt.png" },
  { value: ShopType.WEEZEVENT, name: "Weezevent", label: "WEEZEVENT", isLDLC: true, img: "we.png" },
  { value: ShopType.WETIX, name: "Wetix", label: "WETIX", isLDLC: true, img: "wet.png" },
  { value: ShopType.MICROMANIA, name: "Micromania", label: "MICROMANIA", isMicromania: true, img: "m.png" },
  { value: ShopType.FNAC, name: "Fnac", label: "FNAC", isFnac: true, img: "fn.png" },
  { value: ShopType.PSG, name: "PSG Ticketing", label: "PSG", isPSG: true, img: "psg.png" },
  { value: ShopType.TWICKETS, name: "Twickets", label: "TWICKETS", isTwickets: true, img: "twickets-a.png" },
]
export const retailersTaskList = [
  { value: ShopType.AMAZON, name: "All Tasks", label: "ALL", isAll: true, img: "home.png" },
  { value: ShopType.AMAZON, name: "Amazon", label: "AMAZON", isAmazon: true, img: "amazon.png" },
  { value: ShopType.TICKETMASTER, name: "Ticketmaster", label: "TICKETMASTER", isLDLC: true, img: "tinder.png" },
  { value: ShopType.TICKETMASTER_EU, name: "Ticketmaster europe", label: "TICKETMASTER_EU", isLDLC: true, img: "tinder.png" },
  { value: ShopType.QUEUEIT, name: "Queue-it", label: "QUEUEIT", isLDLC: true, img: "qt.png" },
  { value: ShopType.WEEZEVENT, name: "Weezevent", label: "WEEZEVENT", isLDLC: true, img: "we.png" },
  { value: ShopType.WETIX, name: "Wetix", label: "WETIX", isLDLC: true, img: "wet.png" },
  { value: ShopType.MICROMANIA, name: "Micromania", label: "MICROMANIA", isMicromania: true, img: "m.png" },
  { value: ShopType.FNAC, name: "Fnac", label: "FNAC", isFnac: true, img: "fn.png" },
  { value: ShopType.PSG, name: "PSG Ticketing", label: "PSG", isPSG: true, img: "psg.png" },
  { value: ShopType.TWICKETS, name: "Twickets", label: "TWICKETS", isTwickets: true, img: "twickets-a.png" },
]