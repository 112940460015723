import React from 'react'

function DeleteModal({i, onClick}) {
  return (
    <>
      <div className="modal fade" id={"Delete_Profile_Modal" + i.id} tabIndex="-1" data-bs-backdrop="true" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm ">
          <div className="modal-content">
            <div className="modal-header p-3 pb-1">
              <h5 className='text-light'>Do You Really Want To Delete This ?</h5>
            </div>
            <div className='modal-body '>
              <button className='btn btn-success btn-sm me-2 ' data-bs-dismiss="modal" onClick={onClick}>YES</button>
              <button className='btn btn-danger btn-sm  ' data-bs-dismiss="modal" aria-label="Close">NO</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteModal