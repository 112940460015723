import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Task from './pages/Task';
import Accounts from './pages/Accounts';
import Settings from './pages/Settings';
import Login from './pages/Login';
import LogHistory from "./pages/LogHistory"
import Class from "./pages/Class"
import Loader from './components/Loader';
import { Toaster } from "react-hot-toast"
import PrivateRoute from "./components/PrivateRoute"
import Callback from "./components/callback/Callback";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './css/style.css';
import './css/side-bar.css';
import './css/nav-bar.css';
import './css/right-side.css';
import { components } from 'react-select';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/task" element={<Task />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/account" element={<Accounts />} />
            <Route path="/history" element={<LogHistory />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/class" element={<Class />} />
            <Route path="/loader" element={<Loader />} />
            <Route path="*" element={<Task />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster />
    </>
  );
}

serviceWorkerRegistration.register();
export default App;
