import toast from 'react-hot-toast';

export const toastError = (err) => {
  toast.error(`Something went wrong due to ${err} `, {
    position: 'bottom-center',
    style: {
      background: "black",
      color: "white",
      border: "1px solid #1F2023",
      autoClose: 3000,
    }
  });
};

export const toastSuccess = (res) => {
  toast.success(res, {
    position: 'bottom-center',
    style: {
      background: "black",
      color: "white",
      border: "1px solid #1F2023",
      borderRadius: 20,
      autoClose: 3000,
    }
  });
};