import React, { useEffect, useState } from 'react';
import '../css/task.css';
import LeftMenu from '../components/common/LeftMenu';
import Header from '../components/common/Header';
import UserPreferences from "../components/settings/UserPreferences"
import axios from "axios"
import { toast } from "react-hot-toast"
import { toastError, toastSuccess } from '../components/common/toastUtils';

const KEY_LIVE_UPDATES = "logs-live-updates";

function displayLog(log) {
  const regexMillisecondes = /.(\d{3})]/;
  var response =  log.replace(regexMillisecondes, ']');
  const regexLiens = /(https?:\/\/[^\s]+)/g;

  // Remplacer les liens par des balises <a>
  const logAvecLiens = response.replace(regexLiens, '<a style="text-decoration:none;" class="log_success" href="$1" target="_blank">$1</a>');

  return logAvecLiens
}

export default function Task() {
  const [selectedFilter, setSelectedFilter] = useState();
  const [logs, setLogs] = useState();
  const [hasLiveUpdates, setHasLiveUpdates] = useState(localStorage.getItem(KEY_LIVE_UPDATES) === "on");
  const [selectedTask, setSelectedTask] = useState("all");
  const [runningTasks, setRunningTasks] = useState([]);
  const [stats, setStats] = useState();
  const headers = {
    'ngrok-skip-browser-warning': 'skip',

    'x-user-provided-key': UserPreferences.getServerPassword(),
    'Content-type': 'application/json'
  }
  function startRefreshInterval() {
    localStorage.setItem(KEY_LIVE_UPDATES, "on");
    const _window = window;
    clearInterval(_window[KEY_LIVE_UPDATES]);
    delete _window[KEY_LIVE_UPDATES];

    _window[KEY_LIVE_UPDATES] = setInterval(async () => {
      const taskID = localStorage.getItem("selectedTaskLogID")

      if (taskID === "all" || taskID == null || taskID == "null") {
        getLogs();
      } else {
        getTaskLogs(taskID);
      }
    }, 6000);
  }

  function onLiveUpdateChange() {
    const newValue = !hasLiveUpdates;
    setHasLiveUpdates(newValue);
    const _window = window;
    clearInterval(_window[KEY_LIVE_UPDATES]);

    if (newValue) {
      startRefreshInterval();
    } else {
      localStorage.setItem(KEY_LIVE_UPDATES, "off");
    }
  }

  function getRunningTasks() {
    axios
      .get(UserPreferences.getServerUrl() + "/tasks/running", { headers: headers })
      .then((res) => {
        setRunningTasks(res.data.running_tasks)
      })
      .catch((e) => {
        return console.log(`fetching issue due to ${e}`);
      });
  }

  function getLogs() {
    getRunningTasks()
    axios
      .get(UserPreferences.getServerUrl() + "/v2/logs", { headers: headers })
      .then((res) => {
        setLogs(res.data.logs.logs.reverse())
        setStats(res.data.logs)
      })
      .catch((e) => {
        return console.log(`fetching issue due to ${e}`);
      });
  }
  
  function clearLogs() {
    axios
      .post(UserPreferences.getServerUrl() + "/logs/clear",{}, { headers: headers })
      .then((res) => {
        getLogs()
        toastSuccess("All logs cleared.") 
      })
      .catch((e) => {
        toastError(e)
        return console.log(`fetching issue due to ${e}`);
      });
  }

  function getTaskLogs(id) {
    axios
      .get(UserPreferences.getServerUrl() + "/tasks/" + id + "/log", { headers: headers })
      .then((res) => {
        setLogs(res.data.logs.reverse())
      })
      .catch((e) => {
        return console.log(`fetching issue due to ${e}`);
      });
  }

  useEffect(() => {
    getLogs()
    const urlParams = new URLSearchParams(window.location.search);
    localStorage.setItem("selectedTaskLogID",null)
    if (urlParams.has('task')) {
      const taskValue = urlParams.get('task');
      setSelectedTask(taskValue)
      handleSelectChange({"target":{"value":taskValue}})
      localStorage.setItem("selectedTaskLogID",taskValue)
      getTaskLogs(taskValue)
    }
    
    if (hasLiveUpdates) {
      startRefreshInterval()
    }
  }, []);

  function getClassLog(log) {
    const success_patterns = ["Click here", "Solved"];
    const warning_patterns = ["available"];
    const inqueue_patterns = ["Waiting"];
    const error_patterns = ["error", "Error"];
    const success_regex = new RegExp("\\b(" + success_patterns.join("|") + ")\\b", "i");
    const warning_regex = new RegExp("\\b(" + warning_patterns.join("|") + ")\\b", "i");
    const error_regex = new RegExp("\\b(" + error_patterns.join("|") + ")\\b", "i");
    const inqueue_regex = new RegExp("\\b(" + inqueue_patterns.join("|") + ")\\b", "i");
    if (success_regex.test(log)) {
      return "log_success"
    }
    if (warning_regex.test(log)) {
      return "log_warning"
    }
    if (error_regex.test(log)) {
      return "log_error"
    }
    if (inqueue_regex.test(log)) {
      return "log_inqueue"
    }

  }

  const handleSelectChange = (event) => {
    const selectedOptionId = event.target.value;
    setSelectedTask(selectedOptionId)
    localStorage.setItem("selectedTaskLogID",selectedOptionId)
    if (selectedOptionId === "all") {
      getLogs()
    }
    else {
      getTaskLogs(selectedOptionId)
    }
  };


  return (
    <div className="tasks">
      <div className="main-container">
        <LeftMenu />
        <div className="right-side">
          <Header />
          <div className="middle-section-container flex">
            <div className="full_width_middle border-right-2 profile_middle">
              <div className="profile_item1">
                <div className="col-lg-5 user-info">
                  <h2>Log History</h2>
                  <h5> <span><lable className="c1">{stats?.running_tasks}</lable>Tasks </span>  <span> <lable className="c2">{stats?.checkouts}</lable> Checkouts </span>
                    <span><lable className="c3">{stats?.queues} </lable> in Queue </span>  <span> <lable className="c4">{stats?.errors}</lable>Errors </span>
                  </h5>
                </div>
                <div className="col-lg-3">
                  <div className="select-style">
                    <div className="select_img"><img src={require('../assets/task.png')} />
                    </div>
                    <CustomSelect  options={runningTasks} onSelectChange={handleSelectChange} preselect={selectedTask} />
                    {/* <select>
                      <option value="volvo"> All Tasks</option>
                    </select> */}
                  </div>
                </div>
                <div className="col-lg-3 padding-left-12 clerall auto_refrece relative">
                  <div className="auto_refreces">
                    <div className="auto_img"><img src={require('../assets/refres.png')} />
                    </div>
                    <div className="auto_text"> Auto Refresh </div>
                    <div className="check_referce">
                      <input type="checkbox" hidden="hidden" id="a3" onChange={onLiveUpdateChange} checked={hasLiveUpdates} />
                      <label className="switch" htmlFor="a3" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 " onClick={clearLogs}>
                  <button className="clearall"><a href="#"> Clear All </a></button>
                </div>
              </div>
              <div className="profile_table">
                <div className="col-lg-12 padding-left-right-0">
                  <div className="margin-top-16 table-scrolling table_scroll_history padding-right-5">
                    {logs?.map((res, index) => (
                      <div className="log_his">
                        <p className={getClassLog(res)} dangerouslySetInnerHTML={{ __html: displayLog(res) }}></p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="CreateTask" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-margin">
            <div className="modal-content">
              <div className="modal-header border-2 padding-15">
                <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                  Create Task
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body padding-15">
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <label className="white font-size-13 font-weight-600"> Site</label>
                    <select className="setting-input">
                      <option className="option">Select Site</option>
                      <option className="option">Select Site</option>
                      <option className="option">Select Site</option>
                    </select>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-600"> Mode</label>
                    <select className="setting-input">
                      <option className="option">Select Mode</option>
                      <option className="option">Select Mode</option>
                      <option className="option">Select Mode</option>
                    </select>
                  </div>
                </div>
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <label className="white font-size-13 font-weight-600"> Input</label>
                    <input type="text" placeholder="Enter Amount" className="setting-input" />
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-600"> Size</label>
                    <select className="setting-input">
                      <option className="option">Select Size</option>
                      <option className="option">Select Size</option>
                      <option className="option">Select Size</option>
                    </select>
                  </div>
                </div>
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <label className="white font-size-13 font-weight-600"> Profile</label>
                    <select className="setting-input">
                      <option className="option">Select Profile</option>
                      <option className="option">Select Profile</option>
                      <option className="option">Select Profile</option>
                    </select>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-600"> Proxy</label>
                    <select className="setting-input">
                      <option className="option">Select Proxy</option>
                      <option className="option">Select Proxy</option>
                      <option className="option">Select Proxy</option>
                    </select>
                  </div>
                </div>
                <div className="margin-bottom-15 margin-top-30 text-center">
                  <button className="sava-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Create Tasks
                    <img src="{require('../assets/images/plus.png')}" className="margin-left-15" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="EditTask" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-margin">
            <div className="modal-content">
              <div className="modal-header border-2 padding-15">
                <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                  Edit Task
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body padding-15">
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <div className="select-div">
                      <label className="white font-size-13 font-weight-600"> Site</label>
                      <select>
                        <option className="option">Select Site</option>
                        <option className="option">Select Site</option>
                        <option className="option">Select Site</option>
                      </select>
                    </div>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <div className="select-div">
                      <label className="white font-size-13 font-weight-600"> Size</label>
                      <select>
                        <option className="option">Select Size</option>
                        <option className="option">Select Size</option>
                        <option className="option">Select Size</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-15">
                  <div className="popup-left-selects">
                    <div className="input-div border-2-radius-5 border-2 background-2 width-97">
                      <label className="white font-size-13 font-weight-600"> Profile</label>
                      <input type="text" placeholder="Enter Amount" />
                    </div>
                  </div>
                </div>
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <div className="select-div">
                      <label className="white font-size-13 font-weight-600"> Profile</label>
                      <select>
                        <option className="option">Select Profile</option>
                        <option className="option">Select Profile</option>
                        <option className="option">Select Profile</option>
                      </select>
                    </div>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <div className="select-div">
                      <label className="white font-size-13 font-weight-600"> Proxy</label>
                      <select>
                        <option className="option">Select Proxy</option>
                        <option className="option">Select Proxy</option>
                        <option className="option">Select Proxy</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <div className="select-div">
                      <label className="white font-size-13 font-weight-600"> Mode</label>
                      <select>
                        <option className="option">Select Mode</option>
                        <option className="option">Select Mode</option>
                        <option className="option">Select Mode</option>
                      </select>
                    </div>
                  </div>
                  <div className="popup-right-selects width-49 right">
                    <div className="input-div border-radius-5 border-2 background-2 width-97">
                      <label className="white font-size-13 font-weight-600"> Amount</label>
                      <input type="text" placeholder="Enter Amount" />
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-15 margin-top-30 text-center">
                  <button className="active-btn border-2 border-radius-5 width-50 font-size-13 font-weight-600" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Edit Tasks
                    <img src="{require('../assets/images/shoes.png')}" className="margin-left-15" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="BillingPopup" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-margin">
            <div className="modal-content">
              <div className="modal-header border-2 padding-15">
                <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                  Billing Information
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body padding-15">
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right">
                    <div className="input-div border-radius-5 border-2 background-2 width-95">
                      <label className="white font-size-13 font-weight-600"> First Name</label>
                      <input type="text" placeholder="Enter First Name" />
                    </div>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <div className="input-div border-radius-5 border-2 background-2 width-95">
                      <label className="white font-size-13 font-weight-600"> Last Name</label>
                      <input type="text" placeholder="Enter Last Name" />
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-15">
                  <div className="popup-left-selects">
                    <div className="input-div border-2-radius-5 border-2 background-2 width-97">
                      <label className="white font-size-13 font-weight-600"> Address Line 1</label>
                      <input type="text" placeholder="Enter Address 1" />
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-15">
                  <div className="popup-left-selects">
                    <div className="input-div border-2-radius-5 border-2 background-2 width-97">
                      <label className="white font-size-13 font-weight-600"> Address Line 2</label>
                      <input type="text" placeholder="Enter Address 2" />
                    </div>
                  </div>
                </div>
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <div className="select-div">
                      <label className="white font-size-13 font-weight-600"> City</label>
                      <select>
                        <option className="option">City</option>
                        <option className="option">City</option>
                        <option className="option">City</option>
                      </select>
                    </div>
                  </div>
                  <div className="popup-right-selects width-49 right">
                    <div className="input-div border-radius-5 border-2 background-2 width-97">
                      <label className="white font-size-13 font-weight-600"> Postal Code</label>
                      <input type="text" placeholder="Enter Postal Code" />
                    </div>
                  </div>
                </div>
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <div className="select-div">
                      <label className="white font-size-13 font-weight-600"> State</label>
                      <select>
                        <option className="option">State</option>
                        <option className="option">State </option>
                        <option className="option">State </option>
                      </select>
                    </div>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <div className="select-div">
                      <label className="white font-size-13 font-weight-600"> Counry</label>
                      <select>
                        <option className="option"> Counry</option>
                        <option className="option">Counry </option>
                        <option className="option">Counry </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-15 margin-top-30 text-center">
                  <button className="active-btn border-2 border-radius-5 width-50 font-size-13 font-weight-600" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Save
                    <img src="{require('../assets/images/shoes.png')}" className="margin-left-15" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="EditTask" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-margin">
            <div className="modal-content">
              <div className="modal-header border-2 padding-15">
                <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                  Edit Task
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body padding-15">
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <label className="white font-size-13 font-weight-600"> Site</label>
                    <select>
                      <option className="option">Select Site</option>
                      <option className="option">Select Site</option>
                      <option className="option">Select Site</option>
                    </select>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-600"> Size</label>
                    <select>
                      <option className="option">Select Size</option>
                      <option className="option">Select Size</option>
                      <option className="option">Select Size</option>
                    </select>
                  </div>
                </div>
                <div className="margin-bottom-15">
                  <div className="popup-left-selects">
                    <label className="white font-size-13 font-weight-600"> Profile</label>
                    <input type="text" placeholder="Enter Amount" />
                  </div>
                </div>
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <label className="white font-size-13 font-weight-600"> Profile</label>
                    <select>
                      <option className="option">Select Profile</option>
                      <option className="option">Select Profile</option>
                      <option className="option">Select Profile</option>
                    </select>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-600"> Proxy</label>
                    <select>
                      <option className="option">Select Proxy</option>
                      <option className="option">Select Proxy</option>
                      <option className="option">Select Proxy</option>
                    </select>
                  </div>
                </div>
                <div className="flex margin-bottom-15">
                  <div className="popup-left-selects width-50">
                    <label className="white font-size-13 font-weight-600"> Mode</label>
                    <select>
                      <option className="option">Select Mode</option>
                      <option className="option">Select Mode</option>
                      <option className="option">Select Mode</option>
                    </select>
                  </div>
                  <div className="popup-right-selects width-49 right">
                    <label className="white font-size-13 font-weight-600"> Amount</label>
                    <input type="text" placeholder="Enter Amount" />
                  </div>
                </div>
                <div className="margin-bottom-15 margin-top-30 text-center">
                  <button className="active-btn border-2 border-radius-5 width-50 font-size-13 font-weight-600" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Edit Tasks
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

class CustomSelect extends React.Component {
  render() {
    const { options, onSelectChange, preselect } = this.props;

    return (
      <select id="log_select" onChange={onSelectChange} >
        <option key="all" value="all">All logs</option>
        {options.map((option) => (
          <option key={option.id} value={option.id} selected={preselect == option.id}>
            {option.label} 
          </option>
        ))}
      </select>
    );
  }
}
