import React, { useEffect, useState } from 'react';
import '../css/profile.css';
import LeftMenu from '../components/common/LeftMenu';
import Header from '../components/common/Header';
import { retailersList } from "../config/Config";
import axios from "axios"
import UserPreferences from "../components/settings/UserPreferences"
import { toast } from "react-hot-toast"
import ViewModal from '../components/ViewModal';
import CreateModal from '../components/CreateModal';
import UpdateModal from '../components/UpdateModal';
import DeleteModal from '../components/DeleteModal';
import { toastError, toastSuccess } from '../components/common/toastUtils';

export default function Profile() {
  const [activeIndex, setActiveIndex] = useState(localStorage.getItem("PROFILE_RETAILER_SELECTED"));
  const [profile, setProfile] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [isEmptyProfileList, setIsEmptyProfileList] = useState(false);
  const [filteredProfileList, setFilteredProfileList] = useState([]);
  const [totalProfileCount, setTotalProfileCount] = useState(0);
  const [profileCounts, setProfileCounts] = useState({});
  const [data, setData] = useState({
    label: "",
    login: "",
    password: "",
    shop: "AMAZON",
    webhook: "",
    first_name: "",
    last_name: "",
    email_address: "",
    card_number: "",
    card_expiration_month: "",
    card_expiration_year: "",
    card_cvv: "",
    card_owner: "",
    street_address: "",
    city: "",
    country: "",
    postal_code: "",
    push_phone: ""
  });

  const handleSearchProfilesInput = (event) => {
    var search_pattern = event.target.value
    if (search_pattern.length >= 3){
      let filteredProfileList = profileList.filter(profile => {
        return profile.shop.toLowerCase().includes(search_pattern.toLowerCase()) || profile.label.toLowerCase().includes(search_pattern.toLowerCase());
      });
      setFilteredProfileList(filteredProfileList)
    }
    else {
      setFilteredProfileList(profileList)
    }
  }


  const headers = {
    'ngrok-skip-browser-warning': 'skip',
    'x-user-provided-key': UserPreferences.getServerPassword(),
    'Content-type': 'application/json'
  }

  useEffect(() => {
    const groupIndex = localStorage.getItem("PROFILE_RETAILER_SELECTED")
    setActiveIndex(groupIndex)
    getProfiles(groupIndex)
  },[])

  function duplicateProfile(profile) {
    const copy_profile = profile
    copy_profile.label = copy_profile.label +"-copy"
    axios
      .post(UserPreferences.getServerUrl() + "/profiles", copy_profile, { headers: headers })
      .then((res) => { 
        getProfiles(activeIndex);
        toastSuccess(res.data.msg) })
      .catch((err) => toastError(err));
  }

  function testProfile(profile) {
    axios
      .get(UserPreferences.getServerUrl() + "/profiles/"+profile.id+"/test", { headers: headers })
      .then((res) => { 
        toastSuccess(res.data.msg) })
      .catch((err) => toastError(err));
  }

  const getProfiles = (index) => {
    axios
      .get(UserPreferences.getServerUrl() + "/profiles", { headers: headers })
      .then((res) => { 
        setProfile(res.data);
        if (res.data.length === 0){
          setIsEmptyProfileList(true)
        }
        if (index !== null && index !== undefined && index !== "null"){
          const selectedShop = retailersList[activeIndex].label;
          const filteredProfiles = res.data.filter((profile) => profile.shop === selectedShop);
          setProfileList(filteredProfiles);
          setFilteredProfileList(filteredProfiles);
        }
        else {
          setFilteredProfileList(res.data);
          setProfileList(res.data); 
        }
        calculateProfileCounts(res.data) })
      .catch((e) => { console.error(`fetching issue due to ${e}`) })
  }

  const submitProfile = (event) => {
    event.preventDefault()
    axios
      .post(UserPreferences.getServerUrl() + "/profiles", data, { headers: headers })
      .then((res) => { 
        getProfiles();
        toastSuccess(res.data.msg) })
      .catch((err) => toastError(err));
  }
  const handleEditProfile = (selectedProfile) => {
    setData(selectedProfile);
  }

  const updateProfile = (event, id) => {
    event.preventDefault()
    axios
      .put(UserPreferences.getServerUrl() + `/profiles/${id}`, data, { headers: headers })
      .then((res) => { getProfiles(); toastSuccess(res.data.msg) })
      .catch((err) => toastError(err))
  }

  const deleteProfile = (identity) => {
    axios
      .delete(UserPreferences.getServerUrl() + `/profiles/${identity}`, { headers: headers })
      .then((res) => { 
        const filteredProfiles = profileList.filter((objet) => objet.id !== identity);
        setProfileList(filteredProfiles);
        setFilteredProfileList(filteredProfiles);
        if (filteredProfiles.length == 0){
          setIsEmptyProfileList(true)
        }
        toastSuccess(res.data.msg) 
      })
      .catch((err) => (toastError(err)))
  }

  const handleInputs = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setData((prev) => ({ ...prev, [key]: value }));
  }
  const calculateProfileCounts = (data) => {
    const counts = {};
    let total = 0;
    data.forEach((profile) => {
      const shopName = profile.shop;
      counts[shopName] = counts[shopName] ? counts[shopName] + 1 : 1;
      total++;
    });
    setProfileCounts(counts);
    setTotalProfileCount(total);
  }
  const handleGroupClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    localStorage.setItem("PROFILE_RETAILER_SELECTED",index)
    if (index === null || index === "null") {
      getProfiles(index)
    } else {
      const selectedShop = retailersList[index].label;
      const filteredProfiles = profile.filter((profile) => profile.shop === selectedShop);
      setProfileList(filteredProfiles);
      setFilteredProfileList(filteredProfiles);
      setData({shop: retailersList[index]?.label})
    }
  }

  return (

    <div className="profile">
      <div className="main-container">
        <LeftMenu />
        <div className="right-side">
          <Header />
          <div className="middle-section-container flex">
            <div className="middle-section border-right-2 profile_middle">
              <div className="profile_item1">
                <div className="col-lg-3 user-info">
                  <h2>{retailersList[activeIndex]?.label || "All profiles"}</h2>
                </div>
                <div className="col-lg-6 padding-left-12">
                  <div className="profile_inputsearch">
                    <input type="text" placeholder="Search for profiles, retailers..." onChange={handleSearchProfilesInput} />
                  </div>
                </div>
                <div className="col-lg-3 create_right">
                  <button className="active-btn" onClick={() => setData({ label: "", login: "", password: "", shop: retailersList[activeIndex]?.label || "AMAZON", webhook: "", first_name: "", last_name: "", email_address: "", card_number: "", card_expiration_month: "", card_expiration_year: "", card_cvv: "", card_owner: "", street_address: "", city: "", country: "", postal_code: "", push_phone: "" })} data-bs-toggle="modal" data-bs-target="#Create_Profile_Modal">
                    Create Profile
                    <img src={require('../assets/images/plus.png')} />
                  </button>
                </div>
              </div>
              {profileList.length !== 0 ? (
                <>
                  <div className="profile_table">
                    <div className="col-lg-12 padding-left-right-0">
                      <div className="margin-top-16 table-scrolling padding-right-5 profile_table11">
                        <table className="ab-table-border-2ed" cellPadding="0" border="0">
                          <thead>
                            <tr className="tr-th">
                              {/* <th>
                                <div className="form-group1">
                                  <input type="checkbox" id="html" />
                                  <label htmlFor="html"></label>
                                </div>
                              </th> */}
                              <th>ID</th>
                              <th>Profile Name</th>
                              <th>Login</th>
                              <th>Shop</th>
                              {/* <th>Default</th> */}
                              <th className="text-center">Actions</th>
                            </tr>
                          </thead>

                          {
                            filteredProfileList.map((i) => {
                              return <>
                                <UpdateModal data={data} i={i} retailersList={retailersList} handleInputs={handleInputs} handleSubmit={(event) => { updateProfile(event, i.id) }} />
                                <ViewModal i={i} />
                                <DeleteModal i={i} onClick={() => deleteProfile(i.id)} />
                                <tbody key={i.id}>
                                  <tr className="row_height"> </tr>
                                  <tr>
                                    {/* <td>
                                      <div className="form-group1">
                                        <input type="checkbox" id="html" />
                                        <label htmlFor="html"></label>
                                      </div>
                                    </td> */}
                                    <td>{i.id}</td>
                                    <td>{i.label}</td>
                                    <td>{i.login}</td>
                                    <td><span>{i.shop}</span></td>
                                    {/* <td className="text-center"><i className="fa fa-circle success" aria-hidden="true"></i></td> */}
                                    <td>
                                      <div className="flex right">
                                        <div className="box_pro" onClick={() => testProfile(i)}><img src={require('../assets/play.png')} className="profile-image-hover" /></div>
                                        <div className="box_pro" data-bs-toggle="modal" data-bs-target={"#Update_Profile_Modal" + i.id} onClick={() => handleEditProfile(i)}><img src={require('../assets/edit.png')} className="profile-image-hover" /></div>
                                        <div className="box_pro" onClick={() => duplicateProfile(i)}><img src={require('../assets/copy.png')} className="profile-image-hover" /></div>
                                        <div className="box_pro" data-bs-toggle="modal" data-bs-target={"#Delete_Profile_Modal" + i.id} ><img src={require('../assets/del.png')} className="profile-image-hover" /></div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            })
                          }

                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="profile_table">
                    <div className="col-lg-12 padding-left-right-0">
                      <div className="margin-top-16 table-scrolling padding-right-5 profile_table11">
                        <table className="ab-table-border-2ed" cellPadding="0" border="0">
                          <thead>
                            <tr className="tr-th">
                              <th>
                                <div className="form-group1">
                                  <input type="checkbox" id="html" />
                                  <label htmlFor="html"></label>
                                </div>
                              </th>
                              <th>ID</th>
                              <th>Profile Name</th>
                              <th>Login</th>
                              <th>Shop</th>
                              <th>Default</th>
                              <th className="text-center">Actions</th>
                            </tr>
                          </thead>
                        </table>
                        { isEmptyProfileList && (

                        <div className="profile_nodata No_data">
                          {' '} <img src={require('../assets/no-profile.png')} />{' '}
                          <h2> You have no profiles yet</h2>
                          <p> Create some profiles to get started!</p>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <CreateModal data={data} handleInputs={handleInputs} submitProfile={submitProfile} retailersList={retailersList} index={activeIndex}/>
            </div>
            <>
              <div className="right-sidebar task-groups-right">
                <h2>Profile Groups </h2>
                <div className={`group-task ${(localStorage.getItem("PROFILE_RETAILER_SELECTED") === "null" || localStorage.getItem("PROFILE_RETAILER_SELECTED") === null) ? 'active_group' : ''}`} onClick={() => handleGroupClick(null)}>
                  <div className="flex">
                    <div className="left_profile_active">
                      <img src={require('../assets/3_users.png')} className="profile-image-hover" />
                    </div>
                    <div className="right_pro">
                      <h5> All Profiles</h5>
                      <h4>{totalProfileCount || 0} Profiles</h4>
                    </div>
                  </div>
                </div>

                {retailersList.map((profileGroup, index) => {
                  return <div key={index}>
                    <div className={`group-task ${index == localStorage.getItem("PROFILE_RETAILER_SELECTED") ? 'active_group' : ''}`} onClick={() => handleGroupClick(index)} key={index}>
                      <div className="flex">
                        <div className="left_profile">
                          <img src={require(`../assets/${profileGroup.img}`)} className="profile-image-hover" />
                        </div>
                        <div className="right_pro">
                          <h5>{profileGroup.name}</h5>
                          <h4>{profileCounts[profileGroup.label] || 0} Profiles</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                })
                }
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}