import AuthService from "../authentication/AuthService";
import UserPreferences from "../settings/UserPreferences";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast"

export default function Header() {
  const [me, setMe] = useState();
  const [runningTasks, setRunningTasks] = useState(0);
  const [serverState, setServerState] = useState("UP");
  const [passwordSet, setPasswordSet] = useState(false);
  const [discordimage, setDiscordImage] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [refreshFirst, setRefreshFirst] = useState(true);
  const headers_whop = {
    "Content-Type": "application/json",
    "front-api-key": "Bearer ZGEG",
  }
  const headers = {
    'ngrok-skip-browser-warning': 'skip',
    'x-user-provided-key': UserPreferences.getServerPassword(),

    'Content-type': 'application/json',
    'Accept': 'application/json'
  }

  const toastError = (err) => {
    toast.error(`Something went wrong due to ${err} `, {
      position: 'bottom-center',
      style: {
        background: "black",
        color: "white",
        border: "1px solid #1F2023",
        autoClose: 3000,
      }
    })
  }

  const toastSuccess = (res) => {
    toast.success(res, {
      position: 'bottom-center',
      style: {
        background: "black",
        color: "white",
        border: "1px solid #1F2023",
        borderRadius: 20,
        autoClose: 3000,
      }
    });
  }

  function stopAllTasks(){
    axios
    .get(UserPreferences.getServerUrl() + "/tasks/stop", { headers: headers })
      .then((res) => {
        toastSuccess("All tasks stopped")
      })
      .catch((e) => {
        toastError(e)
        return console.error(`fetching issue due to ${e}`);
      });
  }
  async function refreshUser() {
    axios
      .get("https://r2ebd2n2rlt5fgfkmdoqs6hpga0mdtfw.lambda-url.eu-west-1.on.aws/memberships/" + AuthService.getLicense(), { headers: headers_whop })
      .then((res) => {
        console.log("here2222")
        console.log(res.data)
        var element = res.data
        if (element["license_key"] === AuthService.getLicense()) {
          console.log("here")
          setDiscordImage(element.discord?.image_url)
          setMetadata(element.metadata);
          setMe(element.discord);
          UserPreferences.setServerUrl(element.metadata?.endpoint);
        }
      })
      .catch((e) => {
        return console.error(`fetching issue due to ${e}`);
      });
  }
  async function getTasksStats() {
    axios
      .get(UserPreferences.getServerUrl() + "/tasks/stats", { headers: headers })
      .then((res) => {
        setServerState("UP")
        var result = res.data
        setRunningTasks(result)
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          setServerState("SET_PASSWORD");
        }
        else {
          setServerState("DOWN")
        }
        return console.error(`fetching issue due to ${e}`);
      });
  }

  function refreshComponent(){
    if (localStorage.getItem("refresh_reload") === undefined){
      refreshUser();
      localStorage.setItem("refresh_reload","done")
    }
    getTasksStats();
    setCurrentDate(new Date());
    var settingsLink = document.getElementById('settings');
    
    settingsLink.addEventListener('click', function() {
      // Rediriger l'utilisateur vers la page des paramètres
      window.location.href = 'settings';
    });
    
  }

  function logout(){
    console.log("here")
    localStorage.clear()
    window.location.reload()
  }

  useEffect(() => {
    localStorage.setItem("refresh_reload",undefined)
    refreshUser();
    getTasksStats();
    var settingsLink = document.getElementById('settings');
    settingsLink.addEventListener('click', function() {
      // Rediriger l'utilisateur vers la page des paramètres
      window.location.href = 'settings';
    });
    const intervalId = setInterval(refreshComponent, 10000);

    // Nettoyer l'intervalle lorsque le composant est démonté pour éviter les fuites de mémoire
    return () => clearInterval(intervalId);
  }, []);
  return (
    
    <nav className="flex">


      <div className="nav-left">
        <h3> Welcome back <span>{me?.username}</span> ! <span onClick={logout}><img  alt="" src={require('../../assets/logout.png')} /></span> </h3>
        <ul className="flex adjust-header">
          <li>
            {' '} Authenticated   </li>
          <li>
            {' '}
            {serverState === "UP" ? "Server Operational" : serverState === "DOWN" ? "Server down, please check your AIO" : "Check your password in your settings"} <i className={serverState === "UP" ? "fa fa-circle success" : serverState === "DOWN" ? "fas fa-circle text-danger" : "fas fa-circle text-warning"} aria-hidden="true"></i> {' '}
          </li>
        </ul>
      </div>
      <div className="nav-right text-right">
        <ul className="flex right_list">
          <li>
            {currentDate.toLocaleString('en-US', { month: 'short', day: 'numeric' })} |{' '} <span>{currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
          </li>

          <li>
            {' '}
            Tasks Running: <span>{runningTasks?.running_tasks}</span>  <a href="#" onClick={stopAllTasks}> Stop All </a> {' '}
          </li>
          <li style={{cursor:"pointer"}}>
            {' '}
            <img src={require('../../assets/setting.png')}/> <span id="settings">Settings</span> {' '}
          </li>
        </ul>
      </div>
    </nav>
  );
}
