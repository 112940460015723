import React, { useEffect, useState } from 'react'
import '../css/task.css'
import LeftMenu from '../components/common/LeftMenu'
import Header from '../components/common/Header'
import axios from "axios"
import { aco_method, country_id, method, retailersTaskList, cloudflare_challenge } from "../config/Config"
import UserPreferences from "../components/settings/UserPreferences"
import DeleteModal from '../components/DeleteModal'
import { useNavigate } from 'react-router-dom';
import { toastError, toastSuccess } from '../components/common/toastUtils';

export default function Task() {

  // ############################### VARIABLES ################################# //
  const [checkboxStates, setCheckboxStates] = useState({});
  const [resaleCheckbox, setResaleCheckbox] = useState(false)
  const [forceAvailabilitiesCheckbox, setForceAvailabilitiesCheckbox] = useState(false)
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isQuickLink, setIsQuickLink] = useState(false)
  const [filteredTaskList, setFilteredTaskList] = useState([])
  const [filteredSearchTaskList, setFilteredSearchTaskList] = useState([])
  const [isListSorted, setIsListSorted] = useState(false)
  const [isEmptyTasksList, setIsEmptyTasksList] = useState(true)
  const [selectedRetailerIndex, setSelectedRetailerIndex] = useState(retailersTaskList[0])
  const [selectedProfileShop, setSelectedProfileShop] = useState("")
  const [selectedRetailer, setSelectedRetailer] = useState(localStorage.getItem("TASK_RETAILER_SELECTED"))
  const [taskId, setTaskId] = useState(null)
  const [profiles, setProfiles] = useState([])
  const [proxies, setProxies] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [isRun, setIsRun] = useState(false)
  const [isCheckedMass, setIsCheckedMass] = useState(false)
  const [isEditProxyMass, setIsEditProxyMass] = useState(false)
  const [isCatalog, setIsCatalog] = useState(false)
  const [massData, setMassData] = useState({
    product_id: null,
    offer_id: null,
    expected_success: null,
    quantity: null,
    delay: null,
    proxypool: "",
    category: null,
    date: null,
    price_range: null,
    quantity_range: null,
    cloudflare_challenge : null,
    subscription_type : null,
    edit_proxy: false,
    task_ids: []
  })
  const navigate = useNavigate();
  const [data, setData] = useState({
    aco_payment: "",
    method: "",
    date: "",
    label: "",
    delivery: "",
    payment: "",
    profile: "",
    product_id: "",
    offer_id: "",
    proxypool: "",
    price_limit: 0,
    status: "",
    price_range: "",
    quantity_range: "",
    ticket_category: "",
    tarif: "",
    quantity: 1,
    expected_success: 1,
    delay: 1,
    tasks_amount: 1,
    skip_monitoring: false,
    tm_resaler: false,
    presale_code: "",
    captcha_method: "",
    subscription_type: "",
    cloudflare_challenge : ""
  })

  // ########################################################################### //
  // ############################ GLOBAL CONSTANTS ############################# //
  const headers = {
    'ngrok-skip-browser-warning': 'skip',
    'x-user-provided-key': UserPreferences.getServerPassword(),
    'Content-type': 'application/json'
  }

  function getTaskStatusClassName(label) {
    if (label === "IDLE") {
      return "idle"
    }
    else if (label === "WAITING FOR QUEUE") {
      return "waiting"
    }
    else if (label === "SUCCESS" || label === "COMPLETED") {
      return "success"
    }
    else if (label === "ERROR" || label === "BANNED") {
      return "failed"
    }
    else {
      return "in_progress"
    }
  }

  // ########################################################################### //
  // ########################## FUNCTIONS EVENT LOOP ########################### //
  useEffect(() => {
    const selectedRetailer = localStorage.getItem("TASK_RETAILER_SELECTED")
    setSelectedRetailer(selectedRetailer)
    const foundRetailer = retailersTaskList.find((res) => res.label === selectedRetailer);
    setSelectedRetailerIndex(foundRetailer)

    getProxies()
    getTasks(selectedRetailer)
    const intervalId = setInterval(() => {
      const selectedRetailer = localStorage.getItem("TASK_RETAILER_SELECTED")
      getTasks(selectedRetailer)
    }, 5000);
    // Nettoyez l'intervalle lorsqu'un composant est démonté


    return () => clearInterval(intervalId);
  }, [isListSorted])

  useEffect(() => {
  }, [isEmptyTasksList])

  useEffect(() => {
  }, [selectedProfileShop])

  useEffect(() => {
  }, [checkboxStates])

  useEffect(() => {
  }, [isAllChecked])


  useEffect(() => {
    getProfiles()
    window.location.search.includes('?') ? setIsQuickLink(true) : setIsQuickLink(false)
  }, [isQuickLink])

  useEffect(() => {
    if (window.location.search.includes('?')) {
      const tempObj = {}

      const quickLink = new URLSearchParams(window.location.search.split('?')[1])
      document.getElementById("quickLink").click()

      for (let [param, val] of quickLink) {
        tempObj[param] = val
      }

      setSelectedProfileShop(tempObj.retailer)
      setData(tempObj)
      console.log(tempObj)
    }
  }, [])

  function viewlogs(task) {
    navigate('/history?task=' + task.id)
  }

  // ########################################################################### //
  // ############################### TASK CRUD ################################# //
  const getTasks = (selectedShop) => {
    axios
      .get(UserPreferences.getServerUrl() + "/tasks", { headers: headers })
      .then((res) => {
        if (res.data.length === 0) {
          setIsEmptyTasksList(true);
          setFilteredTaskList([]);
          if (!isListSorted) {
            setFilteredSearchTaskList([])
          }
        } else if (selectedShop === "ALL" || selectedShop === undefined || selectedShop === null) {
          setIsEmptyTasksList(false);
          setFilteredTaskList(res.data);
          if (!isListSorted) {
            setFilteredSearchTaskList(res.data)
          }
        } else {
          const filteredTasks = res.data.filter((task) => task.shop === selectedShop);
          setIsEmptyTasksList(filteredTasks.length === 0);
          setFilteredTaskList(filteredTasks)
          if (!isListSorted) {
            setFilteredSearchTaskList(filteredTasks)
          }
        }
      })
      .catch((e) => {
        console.error(`fetching issue due to ${e}`);
      })
  }

  function duplicateTask(task) {
    const copy_task = task
    copy_task.label = copy_task.label + "-copy"
    axios
      .post(UserPreferences.getServerUrl() + "/tasks", copy_task, { headers: headers })
      .then((res) => { getTasks(selectedRetailer); toastSuccess(res.data.msg); resetData() })
      .catch((err) => toastError(err))
  }

  const submitTask = (event) => {
    event.preventDefault()
    axios
      .post(UserPreferences.getServerUrl() + "/tasks", data, { headers: headers })
      .then((res) => { console.log(res); getTasks(selectedRetailer); if (isRun) { runTasks(event, res.data.tasks_ids) }; toastSuccess(res.data.msg); resetData() })
      .catch((err) => toastError(err))
    const closeButton = document.getElementById('closeCreateModalButton')
    if (closeButton) {
      closeButton.click()
    }
  }

  const updateTask = (event, id) => {
    event.preventDefault()
    axios
      .put(UserPreferences.getServerUrl() + `/tasks/${id}`, data, { headers: headers })
      .then((res) => { console.log(res); getTasks(selectedRetailer); if (isRun) { runTask(event, id) }; toastSuccess(res.data.msg); resetData() })
      .catch((err) => toastError(err))
    const closeButton = document.getElementById('closeUpdateModalButton')
    if (closeButton) {
      closeButton.click()
    }
  }

  function startTasks(event) {
    event.preventDefault()
    const taskIDs = Object.keys(checkboxStates).filter(key => checkboxStates[key] === true);
    axios
      .post(UserPreferences.getServerUrl() + "/tasks/run", { "task_ids": taskIDs }, { headers: headers })
      .then((res) => { getTasks(selectedRetailer); toastSuccess("Run tasks submitted") })
      .catch((err) => toastError(err))
  }

  function stopTasks(event) {
    event.preventDefault()
    const taskIDs = Object.keys(checkboxStates).filter(key => checkboxStates[key] === true);
    axios
      .post(UserPreferences.getServerUrl() + "/tasks/stop", { "task_ids": taskIDs }, { headers: headers })
      .then((res) => { getTasks(selectedRetailer); toastSuccess("Stop tasks submitted") })
      .catch((err) => toastError(err))
  }

  function deleteTasks(event) {
    event.preventDefault()
    const taskIDs = Object.keys(checkboxStates).filter(key => checkboxStates[key] === true);
    axios
      .delete(UserPreferences.getServerUrl() + "/tasks", { headers: headers, data: { "task_ids": taskIDs } })
      .then((res) => { getTasks(selectedRetailer); toastSuccess("Delete tasks submitted") })
      .catch((err) => toastError(err))
  }

  const runTask = (event, id) => {
    event.preventDefault()
    axios
      .post(UserPreferences.getServerUrl() + `/tasks/run`, { "task_ids": [id] }, { headers: headers })
      .then((res) => { getTasks(selectedRetailer); toastSuccess("Task launched successfully") })
      .catch((err) => toastError(err))
  }

  const runTasks = (event, ids) => {
    event.preventDefault()
    axios
      .post(UserPreferences.getServerUrl() + `/tasks/run`, { "task_ids": ids }, { headers: headers })
      .then((res) => { getTasks(selectedRetailer); toastSuccess("Tasks launched successfully") })
      .catch((err) => toastError(err))
  }

  const stopTask = (event, id) => {
    event.preventDefault()
    axios
      .post(UserPreferences.getServerUrl() + `/tasks/${id}/stop`, {}, { headers: headers })
      .then((res) => { getTasks(selectedRetailer); toastSuccess(res.data.msg) })
      .catch((err) => toastError(err))
  }

  const deleteTask = (event, id) => {
    event.preventDefault()
    axios
      .delete(UserPreferences.getServerUrl() + `/tasks/${id}`, { headers: headers })
      .then((res) => {
        const filteredTasks = filteredTaskList.filter((objet) => objet.id !== id);
        setFilteredTaskList(filteredTasks);
        setFilteredSearchTaskList(filteredTasks);
        filteredTasks.length === 0 ? setIsEmptyTasksList(true) : setIsEmptyTasksList(false)
        toastSuccess(res.data.msg)
      })
      .catch((err) => toastError(err))
  }

  // ########################################################################### //
  // ############################## PROXIES CRUD ############################### //
  const getProxies = () => {
    axios
      .get(UserPreferences.getServerUrl() + "/proxypools", { headers: headers })
      .then((res) => { setProxies(res.data) })
      .catch((e) => { console.error(`fetching issue due to ${e}`) })
  }

  const handleCheckboxChange = () => { // this function will check if the checkbox is checked to display the proxy, or not
    setIsChecked(!isChecked)
    if (isChecked) {
      setData((prev) => ({ ...prev, proxypool: "" }))
    }
  }

  // ########################################################################### //
  // ############################## PROFILES CRUD ############################## //
  const getProfiles = () => {
    axios
      .get(UserPreferences.getServerUrl() + "/profiles", { headers: headers })
      .then((res) => { setProfiles(res.data); if (isQuickLink) { setProfiles(res.data.filter(res => res.shop === selectedProfileShop)) } })
      .catch((e) => { console.error(`fetching issue due to ${e}`) })
  }

  // ########################################################################### //
  // ####################### CREATE TASK USEFULL FUNCTION ###################### //
  const handleSearchTasksInput = (event) => {
    setIsListSorted(false)
    var search_pattern = event.target.value
    if (search_pattern.length >= 3) {
      let filtered = filteredTaskList.filter(task => {
        return task.shop.toLowerCase().includes(search_pattern.toLowerCase()) || task.label.toLowerCase().includes(search_pattern.toLowerCase()) || task.profile.toLowerCase().includes(search_pattern.toLowerCase()) || task.proxypool.toLowerCase().includes(search_pattern.toLowerCase());
      });
      setFilteredSearchTaskList(filtered)
    }
    else {
      setFilteredSearchTaskList(filteredTaskList)
    }
  }

  // ########################################################################### //
  // ############################## COMMON FUNCTION ############################ //
  const sort = (prop) => {
    const sortedList = [...filteredSearchTaskList].sort((a, b) => {
      const valueA = a[prop];
      const valueB = b[prop];
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    setFilteredSearchTaskList(sortedList)
    setIsListSorted(true)
  }

  const addCheckboxId = (id) => {
    setCheckboxStates(prevStates => ({
      ...prevStates,
      [id]: !prevStates[id]
    }))
  }

  const addAllCheckboxId = () => {
    setIsAllChecked(!isAllChecked)
    filteredTaskList.forEach(res => {
      setCheckboxStates(prevStates => ({
        ...prevStates,
        [res.id]: !isAllChecked
      }))
    })
  }
  const handleInputs = (event) => {
    if (event.target.name === "product_id") {
      setIsCatalog(event.target.value.includes("/catalogue/"))
    }
    let key = event.target.name;
    let value = event.target.value;
    setData((prev) => ({ ...prev, [key]: value }));
  }

  const handleSelectTaskChange = (event) => {
    setIsListSorted(false)
    const selectedShop = event.target.selectedOptions[0].getAttribute('data-shop');
    setSelectedProfileShop(selectedShop);
    let rawValue = event.target.value;
    const regex = /^[^(]+/
    const value = regex.exec(rawValue)
    setData((prev) => ({ ...prev, profile: value[0].trim() }));
  }

  const handleResaleCheckboxChange = (event) => { // this function will check if the checkbox is checked for resale offers
    setResaleCheckbox(event.target.checked)
    setData((prev) => ({
      ...prev,
      tm_resaler: event.target.checked,
    }))
  }

  const handleForceAvailabilitiesCheckboxChange = (event) => { // this function will check if the checkbox is checked for resale offers
    console.log("Toggle skip monitoring enabled ? ", event.target.checked)
    setForceAvailabilitiesCheckbox(event.target.checked)
    setData((prev) => ({
      ...prev,
      skip_monitoring: event.target.checked,
    }))
  }

  const handleSelectChange = (event) => {
    const selectedShop = event.target.value
    setSelectedRetailer(selectedShop)
    getTasks(selectedShop)
    const foundRetailer = retailersTaskList.find((res) => res.label === selectedShop);
    setSelectedRetailerIndex(foundRetailer) // get the index to get the image
    localStorage.setItem("TASK_RETAILER_SELECTED", selectedShop)
  }

  const handleSelectOptionsChange = (event, key) => {
    let value = event.target.value;
    setData((prev) => ({ ...prev, [key]: value }));
  }

  const resetData = () => {
    setIsRun(false)
    setIsCatalog(false)
    setIsChecked(false)
    setSelectedProfileShop("")
    setData({
      aco_payment: "",
      method: "",
      date: "",
      label: "",
      delivery: "",
      payment: "",
      profile: "",
      product_id: "",
      offer_id: "",
      proxypool: "",
      price_limit: 0,
      status: "",
      price_range: "",
      quantity_range: "",
      ticket_category: "",
      tarif: "",
      quantity: 1,
      expected_success: 1,
      delay: 1,
      tasks_amount: 1,
      tm_resaler: false,
      skip_monitoring: false,
      presale_code: "",
      cloudflare_challenge: "",
      captcha_method: "",
      subscription_type: ""
    })
    if (isQuickLink) { window.location.href = '/tasks' }
  }

  // ########################################################################### //
  // ####################### UPDATE TASK USEFULL FUNCTION ###################### //
  const getShopByProfile = async (profile) => {
    await getProfiles()
    const filter = profiles.filter((res) => res.label === profile)
    return filter[0].shop
  }
  const handleUpdateClick = async (id, i) => {
    const shop = await getShopByProfile(i.profile)
    setSelectedProfileShop(shop)
    i.proxypool === "" ? setIsChecked(false) : setIsChecked(true)
    i.tm_resaler === false ? setResaleCheckbox(false) : setResaleCheckbox(true)
    i.skip_monitoring === false ? setForceAvailabilitiesCheckbox(false) : setForceAvailabilitiesCheckbox(true)

    setData({
      aco_payment: i.aco_payment,
      method: i.method,
      date: i.date,
      label: i.label,
      country_id: i.country_id,
      cloudflare_challenge: i.cloudflare_challenge,
      delivery: i.delivery,
      payment: i.payment,
      profile: i.profile,
      product_id: i.product_id,
      offer_id: i.offer_id,
      proxypool: i.proxypool,
      price_limit: i.price_limit,
      status: i.status,
      price_range: i.price_range,
      quantity_range: i.quantity_range,
      ticket_category: i.ticket_category,
      tarif: i.tarif, quantity: i.quantity,
      expected_success: i.expected_success,
      delay: i.delay, tm_resaler: i.tm_resaler,
      skip_monitoring: i.skip_monitoring,
      presale_code: i.presale_code,
      captcha_method: i.captcha_method,
      subscription_type: i.subscription_type
    })
    setIsCatalog(i.product_id.includes("/catalogue/"))
    setTaskId(id)

    console.log(data)
  }

  const handleUpdateFormSubmit = (event) => {
    event.preventDefault();
    console.log(event.target)
    if (taskId !== null) {
      updateTask(event, taskId)// Utiliser l'ID pour mettre à jour la tâche
    }
  }

  const updateMassTask = (event) => {
    event.preventDefault()
    console.log(massData)
    // Implémenter le post ici
    const taskIDs = Object.keys(checkboxStates).filter(key => checkboxStates[key] === true);
    massData.task_ids = taskIDs
    console.log(massData)
    axios
      .post(UserPreferences.getServerUrl() + "/tasks/massedit", massData, { headers: headers })
      .then((res) => { getTasks(selectedRetailer); toastSuccess(res.data.msg); })
      .catch((err) => toastError(err))

    const closeButton = document.getElementById('closeUpdateMassModalButton')
    if (closeButton) { closeButton.click() }
    resetMass()
  }

  const handleCheckboxChangeEditProxyMass = () => {
    setIsEditProxyMass(!isEditProxyMass)
    const taskIDS = Object.keys(checkboxStates).filter(key => checkboxStates[key] === true)
    setMassData((prev) => ({ ...prev, edit_proxy: !isEditProxyMass, task_ids: taskIDS }))
  }

  const handleCheckboxChangeMass = () => {
    getProxies()
    setIsCheckedMass(!isCheckedMass)
    if (isCheckedMass) {
      setMassData((prev) => ({ ...prev, proxypool: "" }))
    }
  }
  const handleSelectOptionsChangeMass = (event, key) => {
    let value = event.target.value;
    setMassData((prev) => ({ ...prev, [key]: value }))
  }

  const handleInputsMass = (event) => {
    if (event.target.name === "product_id") {
      setIsCatalog(event.target.value.includes("/catalogue/"))
    }
    let { name, value } = event.target;
    const taskIDS = Object.keys(checkboxStates).filter(key => checkboxStates[key] === true)
    setMassData((prev) => ({ ...prev, [name]: value, task_ids: taskIDS }))
  }

  const resetMass = () => {
    setIsCheckedMass(false)
    setIsEditProxyMass(false)
    setMassData((prev) => ({
      ...prev,
      product_id: "",
      offer_id: "",
      expected_success: "",
      quantity: "",
      delay: "",
      category: "",
      proxypool: "",
      date: "",
      price_range: "",
      quantity_range: "",
      edit_proxy: false,
      cloudflare_challenge: "",
      subscription_type: "",
    }))
  }

  // ########################################################################### //
  return (
    <div className="tasks">
      <div className="main-container">
        <LeftMenu />
        <div className="right-side">
          <Header />
          <div className="middle-section-container flex">
            <div className="full_width_middle border-right-2 profile_middle">
              <div className="profile_item1">
                <div className="col-lg-3 user-info">
                  <h2>{selectedRetailer}</h2>
                  <h5> <span> {filteredTaskList.length || 0} Tasks </span>
                    {/* <span> <label>12</label> Selected </span>  */}
                  </h5>
                </div>
                <div className="col-lg-3">
                  <div className="select-style">
                    <div className="select_img"><img src={require(`../assets/${selectedRetailerIndex?.img ? selectedRetailerIndex.img : "task.png"}`)} /></div>
                    <select onChange={(event) => handleSelectChange(event)}>
                      {retailersTaskList.map((res, index) => (
                        <option key={index} className="option" value={res.label} selected={res.label == selectedRetailer}>{res.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 padding-left-12">
                  <div className="profile_inputsearch">
                    <input type="text" placeholder="Search for profiles, retailers..." onChange={handleSearchTasksInput} />
                  </div>
                </div>
                <div className="col-lg-2 create_right">
                  <button id="quickLink" onClick={() => resetData()} className="active-btn" data-bs-toggle="modal" data-bs-target="#Create_task">
                    Create Tasks{' '}
                    <img src={require('../assets/images/plus.png')} />{' '}
                  </button>{' '}
                </div>
              </div>
              <div className="profile_table">
                <div className="col-lg-12 padding-left-right-0">
                  <div className="margin-top-16 table-scrolling table_scroll_task padding-right-5 task_display">
                    <table className="ab-table-border-2ed" cellPadding="0" border="0">
                      <thead>
                        <tr className="tr-th">
                          <th>
                            <div className="form-group1">
                              <input onChange={addAllCheckboxId} checked={isAllChecked} type="checkbox" id="html0" />
                              <label htmlFor="html0"></label>
                            </div>
                          </th>
                          <th onClick={() => sort("id")}>ID</th>
                          <th onClick={() => sort("label")}>label</th>
                          <th onClick={() => sort("profile")}>Profile</th>
                          <th onClick={() => sort("product_id")}>Product ID</th>
                          <th onClick={() => sort("quantity")}>Quantity</th>
                          <th onClick={() => sort("proxypool")}>Proxy</th>
                          <th onClick={() => sort("status")}>Status</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          filteredSearchTaskList.map((i) => {
                            return <>
                              <tr className="row_height"> </tr>
                              <tr>
                                <td>
                                  <div className="form-group1">
                                    <input onChange={() => addCheckboxId(i.id)} checked={checkboxStates[i.id]} type="checkbox" id={"html2" + i.id} />
                                    <label htmlFor={"html2" + i.id}></label>
                                  </div>
                                </td>
                                <td> {i.id}	</td>
                                <td>{i.label.substring(0, 20)}</td>
                                <td>{i.profile.substring(0, 30)}</td>
                                <td>{i.product_id.substring(0, 45)} </td>
                                <td><span>{i.quantity || i.quantity_range}</span></td>
                                <td> {i.proxypool} </td>
                                <td> <span className={getTaskStatusClassName(i.status)}>{i.status}</span> </td>
                                <td>
                                  <div className="flex right">
                                    <div className="box_pro" onClick={(event) => runTask(event, i.id)}><img src={require('../assets/play.png')} className="profile-image-hover" /></div>
                                    <div className="box_pro" onClick={(event) => stopTask(event, i.id)}><img src={require('../assets/paus.png')} className="profile-image-hover" /></div>
                                    <div className="box_pro" onClick={() => { handleUpdateClick(i.id, i) }} data-bs-toggle="modal" data-bs-target="#Update_task"><img src={require('../assets/edit.png')} className="profile-image-hover" /></div> {/* onClick={(event) => updateTask(event, i.id)} */}
                                    <div className="box_pro" onClick={() => { duplicateTask(i) }}><img src={require('../assets/copy.png')} className="profile-image-hover" /></div> {/* onClick={(event) => updateTask(event, i.id)} */}
                                    <div className="box_pro" data-bs-toggle="modal" data-bs-target={"#Delete_Profile_Modal" + i.id}><img src={require('../assets/del.png')} className="profile-image-hover" /></div>
                                    <DeleteModal i={i} onClick={(event) => deleteTask(event, i.id)} />
                                    <div className="box_pro" onClick={() => { viewlogs(i) }}><img style={{ height: "80%" }} src={require('../assets/book.png')} className="profile-image-hover" /></div> {/* onClick={(event) => updateTask(event, i.id)} */}
                                    {/* <div className="box_pro"><img src={require('../assets/dot.png')} className="profile-image-hover" /></div> */}
                                  </div>
                                </td>
                              </tr>
                            </>
                          })
                        }
                      </tbody>
                    </table>
                    {
                      isEmptyTasksList ? (
                        <div className="No_data task_nodata">
                          {' '} <img src={require('../assets/no-task.png')} />{' '}
                          <h2> You have no tasks yet</h2>
                          <p>Create some tasks to get started!</p>
                        </div>
                      ) : (<></>)
                    }
                  </div>
                  <div className="task_buttons task_display">
                    <button className="gren" onClick={(event) => { startTasks(event) }}> Start selected ({Object.keys(checkboxStates).filter(key => checkboxStates[key] === true).length}) <img className="assetsplay" src={require('../assets/play.png')} /> </button>
                    <button className="pink" onClick={(event) => { stopTasks(event) }}> Stop selected ({Object.keys(checkboxStates).filter(key => checkboxStates[key] === true).length}) <img className="assetsstop" src={require('../assets/stop.png')} /> </button>
                    <button className="gray" data-bs-toggle="modal" data-bs-target="#Mass_edit"> Edit All ({Object.keys(checkboxStates).filter(key => checkboxStates[key] === true).length}) <img className="assetsedit" src={require('../assets/edit_q.png')} /> </button>
                    <button className="pink" data-bs-toggle="modal" data-bs-target="#Delete_Mass_tasks" > Delete selected ({Object.keys(checkboxStates).filter(key => checkboxStates[key] === true).length}) <img className="assetsdel" src={require('../assets/del.png')} /> </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* CREATE MODAL */}
        <div className="modal fade" id="Create_task" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-margin">
            <div className="modal-content">
              <div className="modal-header padding-15">
                <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                  Create Tasks
                </h5>
                <button type="button" className="btn-close" onClick={resetData} id="closeCreateModalButton" data-bs-dismiss="modal" aria-label="Close">Close</button>
              </div>
              <form className="modal-body padding-15" method='post' onSubmit={submitTask}>
              
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400">Task Label</label>
                    <input type="text" placeholder="Enter Task Label" name='label' onChange={handleInputs} value={data.label} className="setting-input" required />
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400">Profile</label>
                    <select value={data.profile} onChange={(event) => handleSelectTaskChange(event)} className="select-create-task-modal-style">
                      <option value="" disabled selected>Choose</option>
                      {profiles.map((res, index) => (
                        <option key={index} value={res.label} data-shop={res.shop} >{res.label} ({res.shop})</option>
                      ))
                      }
                    </select>
                  </div>
                </div>
                {selectedProfileShop === "TWICKETS" ? (
                    <>
                      <div>
                      <div className="flex margin-bottom-15">
                        <div className="popup-right-selects width-100 right">
                          <label className="white font-size-13 font-weight-400"> Twickets ID/URL </label>
                          <input type="text" placeholder="Enter Twickets ID/URL" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                        </div>
                      </div>
                    </div>
                    <div className="flex margin-bottom-15">
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Price range </label>
                              <input type="text" placeholder="min-max" name='price_range' onChange={handleInputs} value={data.price_range} className="setting-input" required />
                            </div>
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Quantity range </label>
                              <input type="text" placeholder="min-max" name='quantity_range' onChange={handleInputs} value={data.quantity_range} className="setting-input" required />
                            </div>
                          </div>
                    </>
                  ) :
                    (<></>)
                  }
                {selectedProfileShop === "WEEZEVENT" ? (
                  <>
                    <div>
                      <div className="flex margin-bottom-15">
                        <div className="popup-right-selects width-100 right">
                          <label className="white font-size-13 font-weight-400"> Weezevent ID </label>
                          <input type="text" placeholder="Enter Weezevent ID" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                        </div>
                      </div>
                    </div>
                  </>) : (
                  <>
                    {selectedProfileShop === "WETIX" ? (
                      <div>
                        <div className="flex margin-bottom-15">
                          <div className="popup-right-selects width-100 right">
                            <label className="white font-size-13 font-weight-400"> Wetix URL </label>
                            <input type="text" placeholder="Enter Wetix URL" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                          </div>
                        </div>
                      </div>
                    ) : (<>
                      {selectedProfileShop === "FNAC" ? (<div>
                        <div className="flex margin-bottom-15">
                          <div className="popup-right-selects width-50 right">
                            <label className="white font-size-13 font-weight-400"> Fnac product ID </label>
                            <input type="text" placeholder="Enter Task Label" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                          </div>
                          <div className="popup-right-selects width-50 right">
                            <label className="white font-size-13 font-weight-400"> Payment method  </label>
                            <select value={data.payment} onChange={(event) => { handleSelectOptionsChange(event, 'payment') }} className="select-create-task-modal-style">
                              <option value="" disabled selected>Choose</option>
                              <option value="PAYPAL">Paypal</option>
                              <option value="CC">Carte de crédit/débit</option>
                            </select>
                          </div>
                          <div className="popup-right-selects width-50 right">
                            <label className="white font-size-13 font-weight-400"> Delivery method  </label>
                            <select value={data.delivery} onChange={(event) => { handleSelectOptionsChange(event, 'delivery') }} className="select-create-task-modal-style">
                              <option value="" disabled selected>Choose</option>
                              <option value="HOME">At home</option>
                              <option value="PICKUP">Pickup</option>
                            </select>
                          </div>
                        </div>
                      </div>) : (<>
                        {selectedProfileShop === "TICKETMASTER_EU" || selectedProfileShop === "TICKETMASTER" ? (<>
                          <div className="flex margin-bottom-15">
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Event URL </label>
                              <input type="text" placeholder="Enter Task Label" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                            </div>
                            {selectedProfileShop === "TICKETMASTER" ? (
                              <>
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> Date </label>
                                  <input type="text" placeholder="YYYY-MM-DD" name='date' onChange={handleInputs} value={data.date} className="setting-input" required />
                                </div>
                              </>
                            ) :
                              (<></>)
                            }
                          </div>
                          <div className="flex margin-bottom-15">
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Price range </label>
                              <input type="text" placeholder="min-max" name='price_range' onChange={handleInputs} value={data.price_range} className="setting-input" required />
                            </div>
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Presale code </label>
                              <input type="text" placeholder="Enter Presale code" name='presale_code' onChange={handleInputs} value={data.presale_code} className="setting-input" />
                            </div>
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Category Keyword </label>
                              <input type="text" placeholder="Category Keyword" name='ticket_category' onChange={handleInputs} value={data.ticket_category} className="setting-input" />
                            </div>
                          </div>
                          {selectedProfileShop === "TICKETMASTER" ? (
                            <>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right relative">
                                  <label className="white font-size-13 font-weight-400">Resale</label>
                                  <input type="text" value="Include Resale Offers?" className="setting-input" />
                                  <div className="proxy_check">
                                    <input checked={resaleCheckbox} type="checkbox" onChange={(event) => { handleResaleCheckboxChange(event) }} hidden="hidden" id="a1" />
                                    <label className="switch" htmlFor="a1"></label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) :
                            (<></>)
                          }
                          {selectedProfileShop === "TICKETMASTER_EU" ? (
                            <>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right relative">
                                  <label className="white font-size-13 font-weight-400">Monitoring</label>
                                  <input type="text" value="Skip availability check?" className="setting-input" />
                                  <div className="proxy_check">
                                    <input checked={forceAvailabilitiesCheckbox} type="checkbox" onChange={(event) => { handleForceAvailabilitiesCheckboxChange(event) }} hidden="hidden" id="a1" />
                                    <label className="switch" htmlFor="a1"></label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) :
                            (<></>)
                          }
                        </>) : (<>
                          {selectedProfileShop === "AMAZON" ? (
                            <>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> ASIN </label>
                                  <input type="text" placeholder="Enter Task Label" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                                </div>
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> Amazon country </label>
                                  <select value={data.country_id} onChange={(event) => { handleSelectOptionsChange(event, 'country_id') }} className="select-create-task-modal-style">
                                    <option value="" disabled selected>Choose</option>
                                    {country_id.map((res, index) => (
                                      <option key={index} value={res.value}>{res.label}</option>
                                    ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> Offer ID </label>
                                  <input type="text" placeholder="Enter Task Label" name='offer_id' onChange={handleInputs} value={data.offer_id} className="setting-input" />
                                </div>
                                <div className="popup-right-selects width-50 right">
                                  <label value={data.aco_payment} className="white font-size-13 font-weight-400"> ACO method </label>
                                  <select value={data.aco_payment} onChange={(event) => { handleSelectOptionsChange(event, 'aco_payment') }} className="select-create-task-modal-style">
                                    <option value="" disabled selected>Choose</option>
                                    {aco_method.map((res, index) => (
                                      <option key={index} value={res.value}>{res.label}</option>
                                    ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> Seller </label>
                                  <select value={data.method} onChange={(event) => { handleSelectOptionsChange(event, 'method') }} className="select-create-task-modal-style">
                                    <option value="" disabled selected>Choose</option>
                                    {method.map((res, index) => (
                                      <option key={index} value={res.value}>{res.label}</option>
                                    ))
                                    }
                                  </select>
                                </div>
                                {data.method === "AMAZON_ONLY" || data.method === "" ? (<></>) : (<>
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Price limit </label>
                                    <input type="text" placeholder="Price limit (EUR - €)" name='price_limit' onChange={handleInputs} value={data.price_limit} className="setting-input" />
                                  </div>
                                </>
                                )}
                              </div>
                            </>
                          ) : (<>
                            {selectedProfileShop === "QUEUEIT" ? (<>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-100 right">
                                  <label className="white font-size-13 font-weight-400"> Queue-IT URL </label>
                                  <input type="text" placeholder="Enter Queue-IT URL" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                                </div>
                              </div>
                            </>) : (<>
                              {selectedProfileShop === "MICROMANIA" ? (<>
                                <div className="flex margin-bottom-15">
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Micromania ID </label>
                                    <input type="text" placeholder="Enter Micromania ID" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                                  </div>
                                </div>
                                <div className="flex margin-bottom-15">
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Payment method  </label>
                                    <select value={data.payment} onChange={(event) => { handleSelectOptionsChange(event, 'payment') }} className="select-create-task-modal-style">
                                      <option value="" disabled selected>Choose</option>
                                      <option value="PAYPAL">Paypal</option>
                                      <option value="CC">Carte de crédit/débit</option>
                                    </select>
                                  </div>
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Delivery method  </label>
                                    <select value={data.delivery} onChange={(event) => { handleSelectOptionsChange(event, 'delivery') }} className="select-create-task-modal-style">
                                      <option value="" disabled selected>Choose</option>
                                      <option value="HOME">At home</option>
                                      <option value="PICKUP">Pickup</option>
                                    </select>
                                  </div>
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Captcha solving method  </label>
                                    <select value={data.captcha_method} onChange={(event) => { handleSelectOptionsChange(event, 'captcha_method') }} className="select-create-task-modal-style">
                                      <option value="" disabled selected>Choose</option>
                                      <option value="MANUAL">Manual</option>
                                      <option value="AUTO">Auto</option>
                                    </select>
                                  </div>
                                </div>
                              </>) : (<>
                                {selectedProfileShop === "PSG" ? (
                                  <>
                                    <div className="flex margin-bottom-15">
                                      <div className="popup-right-selects width-100 right">
                                        <label className="white font-size-13 font-weight-400"> Event URL </label>
                                        <input type="text" placeholder="Enter Task Label" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                                      </div>
                                      {isCatalog ? (
                                        <div className="popup-right-selects width-50 right">
                                          <label className="white font-size-13 font-weight-400"> Ticket Type  </label>
                                          <select value={data.subscription_type} onChange={(event) => { handleSelectOptionsChange(event, 'subscription_type') }} className="select-create-task-modal-style">
                                            <option value="" disabled selected>Choose</option>
                                            <option value="GRAND_PUBLIC">Grand Public</option>
                                            <option value="PREMIER">Premier</option>
                                            <option value="ROUGE_ET_BLEU">Rouge et bleu</option>
                                            <option value="HOSPITALITES">Hospitalités</option>
                                            <option value="TRIBUNE">Tribune</option>
                                            <option value="PACK_GRAND_PUBLIC">Pack Grand Public</option>
                                            <option value="PACK_PREMIER">Pack Premier</option>
                                            <option value="PACK_ROUGE_ET_BLEU">Pack Rouge et bleu</option>
                                            <option value="PACK_HOSPITALITES">Pack Hospitalités</option>
                                            <option value="PACK_TRIBUNE">Pack Tribune</option>
                                          </select>
                                        </div>) : (<></>)
                                      }
                                    </div>
                                    <div className="flex margin-bottom-15">
                                      <div className="popup-right-selects width-50 right">
                                        <label className="white font-size-13 font-weight-400"> Price range </label>
                                        <input type="text" placeholder="min-max" name='price_range' onChange={handleInputs} value={data.price_range} className="setting-input" required />
                                      </div>
                                      <div className="popup-right-selects width-50 right">
                                        <label className="white font-size-13 font-weight-400"> Category Keyword </label>
                                        <input type="text" placeholder="Category Keyword" name='ticket_category' onChange={handleInputs} value={data.ticket_category} className="setting-input" />
                                      </div>
                                      <div className="popup-right-selects width-40 right">
                                        <label className="white font-size-13 font-weight-400"> Cloudflare solve mode </label>
                                        <select value={data.cloudflare_challenge} onChange={(event) => { handleSelectOptionsChange(event, 'cloudflare_challenge') }} className="select-create-task-modal-style">
                                          <option value="" disabled selected>Choose</option>
                                          {cloudflare_challenge.map((res, index) => (
                                            <option key={index} value={res.value}>{res.label}</option>
                                          ))
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  </>) : (<></>)}
                              </>)
                              }
                            </>)
                            }
                          </>)
                          }
                        </>)
                        }
                      </>)
                      }
                    </>)
                    }
                  </>
                )
                }
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right relative">
                    <label className="white font-size-13 font-weight-400"> Proxy</label>
                    <input type="text" value="Use my proxies" className="setting-input" />
                    <div className="proxy_check">
                      <input checked={isChecked} onChange={handleCheckboxChange} type="checkbox" hidden="hidden" id="username" />
                      <label className='switch' htmlFor="username"></label>
                    </div>
                  </div>
                  {isChecked ? (
                    <>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400">Proxy Pool</label>
                        <select onChange={(event) => { handleSelectOptionsChange(event, "proxypool") }} name='proxypool' value={data.proxypool} className="setting-input">
                          <option value="" disabled selected>Choose</option>
                          {proxies.map((res, index) => (
                            <option key={index} className="option" value={res.label}>{res.label}</option>
                          ))}
                        </select>
                      </div>
                    </>) : (<></>)}
                </div>
                {selectedProfileShop === "QUEUEIT" || selectedProfileShop === "WEEZEVENT" || selectedProfileShop === "WETIX" ? (
                  <>
                    <div className="flex margin-bottom-15">
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Number of threads  </label>
                        <input type="number" placeholder="Enter Quantity" name='quantity' onChange={handleInputs} value={data.quantity} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Delay  </label>
                        <input type="number" placeholder="delay in seconds" name='delay' onChange={handleInputs} value={data.delay} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Bulk creation  </label>
                        <input type="number" placeholder="# of tasks" name='tasks_amount' onChange={handleInputs} value={data.tasks_amount} className="setting-input" required />
                      </div>
                    </div>
                  </>) : (<></>)}
                  { selectedProfileShop === "FNAC" || selectedProfileShop === "AMAZON" || selectedProfileShop === "PSG" || selectedProfileShop === "TICKETMASTER" || selectedProfileShop === "TICKETMASTER_EU" ?(
                  <>
                    <div className="flex margin-bottom-15">
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Expected Success  </label>
                        <input type="number" placeholder="Enter Expected Success" name='expected_success' onChange={handleInputs} value={data.expected_success} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Quantity  </label>
                        <input type="number" placeholder="Enter Quantity" name='quantity' onChange={handleInputs} value={data.quantity} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Delay  </label>
                        <input type="number" placeholder="delay in seconds" name='delay' onChange={handleInputs} value={data.delay} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Bulk creation</label>
                        <input type="number" placeholder="# of tasks" name='tasks_amount' onChange={handleInputs} value={data.tasks_amount} className="setting-input" required />
                      </div>
                    </div>
                  </>) : (<></>)
                }
                
                  { selectedProfileShop === "TWICKETS" ?(
                  <>
                    <div className="flex margin-bottom-15">
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Expected Success  </label>
                        <input type="number" placeholder="Enter Expected Success" name='expected_success' onChange={handleInputs} value={data.expected_success} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Delay  </label>
                        <input type="number" placeholder="delay in seconds" name='delay' onChange={handleInputs} value={data.delay} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Bulk creation</label>
                        <input type="number" placeholder="# of tasks" name='tasks_amount' onChange={handleInputs} value={data.tasks_amount} className="setting-input" required />
                      </div>
                    </div>
                  </>) : (<></>)
                }
                <div className="flex">
                  <div className="margin-bottom-15 margin-top-10 text-left">
                    <button className="active-btn" type='submit' onClick={() => setIsRun(false)}>
                      Create Tasks{' '} <img src={require('../assets/plus.png')} />{' '} </button>{' '}
                  </div>
                  <div className="margin-bottom-15 margin-top-10 text-left">
                    <button className="active-btn runbtn" type='submit' onClick={() => setIsRun(true)}>
                      Create and Run{' '} <img src={require('../assets/play.png')} />{' '} </button>{' '}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* UPDATE MODAL */}
        <div className="modal fade" id="Update_task" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-margin">
            <div className="modal-content">
              <div className="modal-header padding-15">
                <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                  Update Task
                </h5>
                <button type="button" className="btn-close" onClick={resetData} id='closeUpdateModalButton' data-bs-dismiss="modal" aria-label="Close">Close</button>
              </div>
              <form className="modal-body padding-15" method='post' onSubmit={(event) => { handleUpdateFormSubmit(event) }}>
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400">Task Label</label>
                    <input type="text" placeholder="Enter Task Label" name='label' onChange={handleInputs} value={data.label} className="setting-input" required />
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400">Profile</label>
                    <select onChange={(event) => handleSelectTaskChange(event)} value={data.profile} className="select-create-task-modal-style">
                      {profiles.map((res, index) => (
                        <option key={index} value={res.label} data-shop={res.shop}>{res.label} ({res.shop}) </option>
                      ))
                      }
                    </select>
                  </div>
                </div>
                {selectedProfileShop === "TWICKETS" ? (
                    <>
                      <div>
                      <div className="flex margin-bottom-15">
                        <div className="popup-right-selects width-100 right">
                          <label className="white font-size-13 font-weight-400"> Twickets ID/URL </label>
                          <input type="text" placeholder="Enter Twickets ID/URL" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                        </div>
                      </div>
                    </div>
                    <div className="flex margin-bottom-15">
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Price range </label>
                              <input type="text" placeholder="min-max" name='price_range' onChange={handleInputs} value={data.price_range} className="setting-input" required />
                            </div>
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Quantity range </label>
                              <input type="text" placeholder="min-max" name='quantity_range' onChange={handleInputs} value={data.quantity_range} className="setting-input" required />
                            </div>
                          </div>
                    </>
                  ) :
                    (<></>)
                  }
                {selectedProfileShop === "WEEZEVENT" ? (
                  <>
                    <div>
                      <div className="flex margin-bottom-15">
                        <div className="popup-right-selects width-100 right">
                          <label className="white font-size-13 font-weight-400"> Weezevent ID </label>
                          <input type="text" placeholder="Enter Weezevent ID" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                        </div>
                      </div>
                    </div>
                  </>) : (
                  <>
                    {selectedProfileShop === "WETIX" ? (
                      <div>
                        <div className="flex margin-bottom-15">
                          <div className="popup-right-selects width-100 right">
                            <label className="white font-size-13 font-weight-400"> Wetix URL </label>
                            <input type="text" placeholder="Enter Wetix URL" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                          </div>
                        </div>
                      </div>
                    ) : (<>
                      {selectedProfileShop === "FNAC" ? (<div>
                        <div className="flex margin-bottom-15">
                          <div className="popup-right-selects width-50 right">
                            <label className="white font-size-13 font-weight-400"> Fnac product ID </label>
                            <input type="text" placeholder="Enter Task Label" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                          </div>
                          <div className="popup-right-selects width-50 right">
                            <label className="white font-size-13 font-weight-400"> Payment method  </label>
                            <select value={data.payment} onChange={(event) => { handleSelectOptionsChange(event, 'payment') }} className="select-create-task-modal-style">
                              <option value="" disabled selected>Choose</option>
                              <option value="PAYPAL">Paypal</option>
                              <option value="CC">Carte de crédit/débit</option>
                            </select>
                          </div>
                          <div className="popup-right-selects width-50 right">
                            <label className="white font-size-13 font-weight-400"> Delivery method  </label>
                            <select value={data.delivery} onChange={(event) => { handleSelectOptionsChange(event, 'delivery') }} className="select-create-task-modal-style">
                              <option value="" disabled selected>Choose</option>
                              <option value="HOME">At home</option>
                              <option value="PICKUP">Pickup</option>
                            </select>
                          </div>
                        </div>
                      </div>) : (<>
                        {selectedProfileShop === "TICKETMASTER_EU" || selectedProfileShop === "TICKETMASTER" ? (<>
                          <div className="flex margin-bottom-15">
                            <div className="popup-right-selects width-100 right">
                              <label className="white font-size-13 font-weight-400"> Event URL </label>
                              <input type="text" placeholder="Enter Task Label" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                            </div>
                            {selectedProfileShop === "TICKETMASTER" ? (
                              <>
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> Date </label>
                                  <input type="text" placeholder="YYYY-MM-DD" name='date' onChange={handleInputs} value={data.date} className="setting-input" required />
                                </div>
                              </>
                            ) :
                              (<></>)
                            }
                          </div>
                          <div className="flex margin-bottom-15">
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Price range </label>
                              <input type="text" placeholder="min-max" name='price_range' onChange={handleInputs} value={data.price_range} className="setting-input" required />
                            </div>
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Presale code </label>
                              <input type="text" placeholder="Enter Presale code" name='presale_code' onChange={handleInputs} value={data.presale_code} className="setting-input" />
                            </div>
                            <div className="popup-right-selects width-50 right">
                              <label className="white font-size-13 font-weight-400"> Category Keyword </label>
                              <input type="text" placeholder="Category Keyword" name='ticket_category' onChange={handleInputs} value={data.ticket_category} className="setting-input" />
                            </div>
                          </div>
                          {selectedProfileShop === "TICKETMASTER" ? (
                            <>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right relative">
                                  <label className="white font-size-13 font-weight-400">Resale</label>
                                  <input type="text" value="Include Resale Offers?" className="setting-input" />
                                  <div className="proxy_check">
                                    <input checked={resaleCheckbox} type="checkbox" onChange={(event) => { handleResaleCheckboxChange(event) }} hidden="hidden" id="a1" />
                                    <label className="switch" htmlFor="a1"></label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) :
                            (<></>)
                          }
                          {selectedProfileShop === "TICKETMASTER_EU" ? (
                            <>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right relative">
                                  <label className="white font-size-13 font-weight-400">Monitoring</label>
                                  <input type="text" value="Skip availability check?" className="setting-input" />
                                  <div className="proxy_check">
                                    <input checked={forceAvailabilitiesCheckbox} type="checkbox" onChange={(event) => { handleForceAvailabilitiesCheckboxChange(event) }} hidden="hidden" id="a1" />
                                    <label className="switch" htmlFor="a1"></label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) :
                            (<></>)
                          }
                        </>) : (<>
                          {selectedProfileShop === "AMAZON" ? (
                            <>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> ASIN </label>
                                  <input type="text" placeholder="Enter Task Label" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                                </div>
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> Amazon country </label>
                                  <select value={data.country_id} onChange={(event) => { handleSelectOptionsChange(event, 'country_id') }} className="select-create-task-modal-style">
                                    <option value="" disabled selected>Choose</option>
                                    {country_id.map((res, index) => (
                                      <option key={index} value={res.value}>{res.label}</option>
                                    ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> Offer ID </label>
                                  <input type="text" placeholder="Enter Task Label" name='offer_id' onChange={handleInputs} value={data.offer_id} className="setting-input" />
                                </div>
                                <div className="popup-right-selects width-50 right">
                                  <label value={data.aco_payment} className="white font-size-13 font-weight-400"> ACO method </label>
                                  <select value={data.aco_payment} onChange={(event) => { handleSelectOptionsChange(event, 'aco_payment') }} className="select-create-task-modal-style">
                                    <option value="" disabled selected>Choose</option>
                                    {aco_method.map((res, index) => (
                                      <option key={index} value={res.value}>{res.label}</option>
                                    ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-50 right">
                                  <label className="white font-size-13 font-weight-400"> Seller </label>
                                  <select value={data.method} onChange={(event) => { handleSelectOptionsChange(event, 'method') }} className="select-create-task-modal-style">
                                    <option value="" disabled selected>Choose</option>
                                    {method.map((res, index) => (
                                      <option key={index} value={res.value}>{res.label}</option>
                                    ))
                                    }
                                  </select>
                                </div>
                                {data.method === "AMAZON_ONLY" || data.method === "" ? (<></>) : (<>
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Price limit </label>
                                    <input type="text" placeholder="Price limit (EUR - €)" name='price_limit' onChange={handleInputs} value={data.price_limit} className="setting-input" />
                                  </div>
                                </>
                                )}
                              </div>
                            </>
                          ) : (<>
                            {selectedProfileShop === "QUEUEIT" ? (<>
                              <div className="flex margin-bottom-15">
                                <div className="popup-right-selects width-100 right">
                                  <label className="white font-size-13 font-weight-400"> Queue-IT URL </label>
                                  <input type="text" placeholder="Enter Queue-IT URL" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                                </div>
                              </div>
                            </>) : (<>
                              {selectedProfileShop === "MICROMANIA" ? (<>
                                <div className="flex margin-bottom-15">
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Micromania ID </label>
                                    <input type="text" placeholder="Enter Micromania ID" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                                  </div>
                                </div>
                                <div className="flex margin-bottom-15">
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Payment method  </label>
                                    <select value={data.payment} onChange={(event) => { handleSelectOptionsChange(event, 'payment') }} className="select-create-task-modal-style">
                                      <option value="" disabled selected>Choose</option>
                                      <option value="PAYPAL">Paypal</option>
                                      <option value="CC">Carte de crédit/débit</option>
                                    </select>
                                  </div>
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Delivery method  </label>
                                    <select value={data.delivery} onChange={(event) => { handleSelectOptionsChange(event, 'delivery') }} className="select-create-task-modal-style">
                                      <option value="" disabled selected>Choose</option>
                                      <option value="HOME">At home</option>
                                      <option value="PICKUP">Pickup</option>
                                    </select>
                                  </div>
                                  <div className="popup-right-selects width-50 right">
                                    <label className="white font-size-13 font-weight-400"> Captcha solving method  </label>
                                    <select value={data.captcha_method} onChange={(event) => { handleSelectOptionsChange(event, 'captcha_method') }} className="select-create-task-modal-style">
                                      <option value="" disabled selected>Choose</option>
                                      <option value="MANUAL">Manual</option>
                                      <option value="AUTO">Auto</option>
                                    </select>
                                  </div>
                                </div>
                              </>) : (<>
                                {selectedProfileShop === "PSG" ? (
                                  <>
                                    <div className="flex margin-bottom-15">
                                      <div className="popup-right-selects width-100 right">
                                        <label className="white font-size-13 font-weight-400"> Event URL </label>
                                        <input type="text" placeholder="Enter Task Label" name='product_id' onChange={handleInputs} value={data.product_id} className="setting-input" required />
                                      </div>
                                      { isCatalog ? (
                                        <div className="popup-right-selects width-50 right">
                                          <label className="white font-size-13 font-weight-400"> Ticket Type  </label>
                                          <select value={data.subscription_type} onChange={(event) => { handleSelectOptionsChange(event, 'subscription_type') }} className="select-create-task-modal-style">
                                            <option value="" disabled selected>Choose</option>
                                            <option value="GRAND_PUBLIC">Grand Public</option>
                                            <option value="PREMIER">Premier</option>
                                            <option value="ROUGE_ET_BLEU">Rouge et bleu</option>
                                            <option value="HOSPITALITES">Hospitalités</option>
                                            <option value="TRIBUNE">Tribune</option>
                                            <option value="PACK_GRAND_PUBLIC">Pack Grand Public</option>
                                            <option value="PACK_PREMIER">Pack Premier</option>
                                            <option value="PACK_ROUGE_ET_BLEU">Pack Rouge et bleu</option>
                                            <option value="PACK_HOSPITALITES">Pack Hospitalités</option>
                                            <option value="PACK_TRIBUNE">Pack Tribune</option>
                                          </select>
                                        </div>) : (<></>)
                                      }
                                    </div>
                                    <div className="flex margin-bottom-15">
                                      <div className="popup-right-selects width-20 right">
                                        <label className="white font-size-13 font-weight-400"> Price range </label>
                                        <input type="text" placeholder="min-max" name='price_range' onChange={handleInputs} value={data.price_range} className="setting-input" required />
                                      </div>
                                      <div className="popup-right-selects width-40 right">
                                        <label className="white font-size-13 font-weight-400"> Category Keyword </label>
                                        <input type="text" placeholder="Category Keyword" name='ticket_category' onChange={handleInputs} value={data.ticket_category} className="setting-input" />
                                      </div>
                                      <div className="popup-right-selects width-40 right">
                                        <label className="white font-size-13 font-weight-400"> Cloudflare solve mode </label>
                                        <select value={data.cloudflare_challenge} onChange={(event) => { handleSelectOptionsChange(event, 'cloudflare_challenge') }} className="select-create-task-modal-style">
                                          <option value="" disabled selected>Choose</option>
                                          {cloudflare_challenge.map((res, index) => (
                                            <option key={index} value={res.value}>{res.label}</option>
                                          ))
                                          }
                                        </select>
                                      </div>
                                    </div>

                                  </>
                                ) : (<></>)
                                }</>)
                              }
                            </>)
                            }
                          </>)
                          }
                        </>)
                        }
                      </>)
                      }
                    </>)
                    }
                  </>
                )
                }
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right relative">
                    <label className="white font-size-13 font-weight-400"> Proxy</label>
                    <input type="text" value="Use my proxies" className="setting-input" />
                    <div className="proxy_check">
                      <input checked={isChecked} onChange={handleCheckboxChange} type="checkbox" hidden="hidden" id="username" />
                      <label className="switch" htmlFor="username"></label>
                    </div>
                  </div>
                  {isChecked ? (
                    <>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400">Proxy Pool</label>
                        <select onChange={(event) => { handleSelectOptionsChange(event, "proxypool") }} name='proxypool' value={data.proxypool} className="setting-input">
                          <option value="" disabled selected>Choose</option>
                          {proxies.map((res, index) => (
                            <option key={index} className="option" value={res.label}>{res.label}</option>
                          ))}
                        </select>
                      </div>
                    </>) : (<></>)}
                </div>
                {selectedProfileShop === "QUEUEIT" || selectedProfileShop === "WEEZEVENT" || selectedProfileShop === "WETIX" ? (
                  <>
                    <div className="flex margin-bottom-15">
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Number of threads  </label>
                        <input type="number" placeholder="Enter Quantity" name='quantity' onChange={handleInputs} value={data.quantity} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Delay  </label>
                        <input type="number" placeholder="delay in seconds" name='delay' onChange={handleInputs} value={data.delay} className="setting-input" required />
                      </div>
                    </div>
                  </>) : (
                  <>
                  </>)
                }
                { selectedProfileShop === "FNAC" || selectedProfileShop === "AMAZON" || selectedProfileShop === "PSG" || selectedProfileShop === "TICKETMASTER" || selectedProfileShop === "TICKETMASTER_EU" ?(
                  <>
                    <div className="flex margin-bottom-15">
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Expected Success  </label>
                        <input type="number" placeholder="Enter Expected Success" name='expected_success' onChange={handleInputs} value={data.expected_success} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Quantity  </label>
                        <input type="number" placeholder="Enter Quantity" name='quantity' onChange={handleInputs} value={data.quantity} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Delay  </label>
                        <input type="number" placeholder="delay in seconds" name='delay' onChange={handleInputs} value={data.delay} className="setting-input" required />
                      </div>
                    </div>
                  </>) : (<></>)
                }
                
                { selectedProfileShop === "TWICKETS" ?(
                  <>
                    <div className="flex margin-bottom-15">
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Expected Success  </label>
                        <input type="number" placeholder="Enter Expected Success" name='expected_success' onChange={handleInputs} value={data.expected_success} className="setting-input" required />
                      </div>
                      <div className="popup-right-selects width-50 right">
                        <label className="white font-size-13 font-weight-400"> Delay  </label>
                        <input type="number" placeholder="delay in seconds" name='delay' onChange={handleInputs} value={data.delay} className="setting-input" required />
                      </div>
                    </div>
                  </>) : (<></>)
                }
                <div className="flex">
                  <div className="margin-bottom-15 margin-top-10 text-left">
                    <button className="active-btn" type='submit' onClick={() => setIsRun(false)}>
                      Update Task{' '} <img src={require('../assets/plus.png')} />{' '} </button>{' '}
                  </div>
                  <div className="margin-bottom-15 margin-top-10 text-left">
                    <button className="active-btn runbtn" type='submit' onClick={() => setIsRun(true)}>
                      Update and Run{' '} <img src={require('../assets/play.png')} />{' '} </button>{' '}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <>
        <div className="modal fade" id="Mass_edit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-margin">
            <div className="modal-content">
              <div className="modal-header padding-15">
                <h5 className="modal-title white font-size-15 font-weight-600" id="exampleModalLabel">
                  Mass Edit
                </h5>
                <button type="button" id='closeUpdateMassModalButton' className="btn-close" data-bs-dismiss="modal" onClick={resetMass} aria-label="Close">Close</button>
              </div>
              <form className="modal-body padding-15" onSubmit={updateMassTask} method='post' >
              <h6 className="dark-blue-color font-size-13 font-weight-200" id="exampleModalLabel">
                    <i>Common Fields</i>
                    <hr class="solid" className='dark-blue-color' style={{"margin-top":"0px"}}></hr>
                </h6>
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-100 right">
                    <label className="white font-size-13 font-weight-400"> Product ID / Queue URL / Event ID </label>
                    <input type="text" placeholder="Leave blank to avoid edit" name='product_id' onChange={handleInputsMass} value={massData.product_id} className="setting-input" />
                  </div>
                </div>
                <div className="flex margin-bottom-15" >
                  <div className="popup-right-selects width-50 right relative">
                    <label className="white font-size-13 font-weight-400">Proxy</label>
                    <input type="text" value="Do you want to mass edit proxy ?" className="setting-input" />
                    <div className="proxy_check">
                      <input checked={isEditProxyMass} onChange={handleCheckboxChangeEditProxyMass} type="checkbox" hidden="hidden" id="isEditProxyMass" />
                      <label className='switch' htmlFor="isEditProxyMass"></label>
                    </div>
                  </div>
                </div>
                {isEditProxyMass ? (<>
                  <div className="flex margin-bottom-15">
                    <div className="popup-right-selects width-50 right relative">
                      <label className="white font-size-13 font-weight-400">Proxy</label>
                      <input type="text" value="Use my proxies" className="setting-input" />
                      <div className="proxy_check">
                        <input checked={isCheckedMass} onChange={handleCheckboxChangeMass} type="checkbox" hidden="hidden" id="proxypoolId" />
                        <label className='switch' htmlFor="proxypoolId"></label>
                      </div>
                    </div>
                    {isCheckedMass ? (
                      <>
                        <div className="popup-right-selects width-50 right">
                          <label className="white font-size-13 font-weight-400">Proxy Pool</label>
                          <select onChange={(event) => { handleSelectOptionsChangeMass(event, "proxypool") }} name='proxypool' value={massData.proxypool} className="setting-input">
                            <option value="" disabled selected>Choose</option>
                            {proxies.map((res, index) => (
                              <option key={index} className="option" value={res.label}>{res.label}</option>
                            ))}
                          </select>
                        </div>
                      </>) : (<></>)}
                  </div>
                </>) : (<></>)
                }
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400"> Expected Success  </label>
                    <input type="number" placeholder="Leave blank to avoid edit" name='expected_success' onChange={handleInputsMass} value={massData.expected_success} className="setting-input" />
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400"> Quantity  </label>
                    <input type="number" placeholder="Leave blank to avoid edit" name='quantity' onChange={handleInputsMass} value={massData.quantity} className="setting-input" />
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400"> Delay  </label>
                    <input type="number" placeholder="Leave blank to avoid edit" name='delay' onChange={handleInputsMass} value={massData.delay} className="setting-input" />
                  </div>
                </div>
                <h6 className="dark-blue-color font-size-13 font-weight-200" id="exampleModalLabel">
                    <i>Amazon Fields</i>
                    <hr class="solid" className='dark-blue-color' style={{"margin-top":"0px"}}></hr>
                </h6>
                <div className="flex margin-bottom-15">
                  <div className="flex margin-bottom-15">
                    <div className="popup-right-selects width-100 right">
                      <label className="white font-size-13 font-weight-400"> Offer ID (Amazon) </label>
                      <input type="text" placeholder="Leave blank to avoid edit" name='offer_id' onChange={handleInputsMass} value={massData.offer_id} className="setting-input" />
                    </div>
                  </div>
                </div>
                <h6 className="dark-blue-color font-size-13 font-weight-200" id="exampleModalLabel">
                    <i>PSG Fields</i>
                    <hr class="solid" className='dark-blue-color' style={{"margin-top":"0px"}}></hr>
                </h6>
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400"> Ticket Type  </label>
                    <select value={massData.subscription_type} onChange={(event) => { handleSelectOptionsChangeMass(event, 'subscription_type') }} className="select-create-task-modal-style">
                      <option value="" selected>Choose</option>
                      <option value="GRAND_PUBLIC">Grand Public</option>
                      <option value="PREMIER">Premier</option>
                      <option value="ROUGE_ET_BLEU">Rouge et bleu</option>
                      <option value="HOSPITALITES">Hospitalités</option>
                      <option value="TRIBUNE">Tribune</option>
                      <option value="PACK_GRAND_PUBLIC">Pack Grand Public</option>
                      <option value="PACK_PREMIER">Pack Premier</option>
                      <option value="PACK_ROUGE_ET_BLEU">Pack Rouge et bleu</option>
                      <option value="PACK_HOSPITALITES">Pack Hospitalités</option>
                      <option value="PACK_TRIBUNE">Pack Tribune</option>
                    </select>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400"> Cloudflare solve</label>
                    <select value={massData.cloudflare_challenge} onChange={(event) => { handleSelectOptionsChangeMass(event, 'cloudflare_challenge') }} className="select-create-task-modal-style">
                      <option value="" selected>Choose</option>
                      {cloudflare_challenge.map((res, index) => (
                        <option key={index} value={res.value}>{res.label}</option>
                      ))
                      }
                    </select>
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400"> Category </label>
                    <input type="text" placeholder="Leave blank to avoid edit" name='category' onChange={handleInputsMass} value={massData.category} className="setting-input" />
                  </div>
                  <div className="popup-right-selects width-50 right">
                      <label className="white font-size-13 font-weight-400"> Price range </label>
                      <input type="text" placeholder="Leave blank to avoid edit" name='price_range' onChange={handleInputsMass} value={massData.price_range} className="setting-input" />
                    </div>
                </div>
                <h6 className="dark-blue-color font-size-13 font-weight-200" id="exampleModalLabel">
                    <i>Ticketmaster Fields</i>
                    <hr class="solid" className='dark-blue-color' style={{"margin-top":"0px"}}></hr>
                </h6>
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right">
                      <label className="white font-size-13 font-weight-400"> Event date </label>
                      <input type="text" placeholder="Leave blank to avoid edit" name='date' onChange={handleInputsMass} value={massData?.date} className="setting-input" />
                    </div>
                    <div className="popup-right-selects width-50 right">
                      <label className="white font-size-13 font-weight-400"> Price range </label>
                      <input type="text" placeholder="Leave blank to avoid edit" name='price_range' onChange={handleInputsMass} value={massData.price_range} className="setting-input" />
                    </div>
                    <div className="popup-right-selects width-50 right">
                      <label className="white font-size-13 font-weight-400"> Category </label>
                      <input type="text" placeholder="Leave blank to avoid edit" name='category' onChange={handleInputsMass} value={massData.category} className="setting-input" />
                    </div>
                </div>
                <h6 className="dark-blue-color font-size-13 font-weight-200" id="exampleModalLabel">
                    <i>Twickets Fields</i>
                    <hr class="solid" className='dark-blue-color' style={{"margin-top":"0px"}}></hr>
                </h6>
                <div className="flex margin-bottom-15">
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400"> Quantity Range  </label>
                    <input type="text" placeholder="Leave blank to avoid edit" name='quantity_range' onChange={handleInputsMass} value={massData.quantity_range} className="setting-input" />
                  </div>
                  <div className="popup-right-selects width-50 right">
                    <label className="white font-size-13 font-weight-400"> Price range </label>
                    <input type="text" placeholder="Leave blank to avoid edit" name='price_range' onChange={handleInputsMass} value={massData.price_range} className="setting-input" />
                  </div>
                </div>
                  
                
                <div className="margin-bottom-15 margin-top-30 text-left">
                  <button type='submit' className="active-btn">
                    Update tasks <img src={require('../assets/images/plus.png')} /></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
      <>
        <div className="modal fade" id="Delete_Mass_tasks" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-margin">
            <div className="modal-content">
              <div className="modal-header p-3 pb-1">
                <h5 className='text-light'>Do You Really Want To Delete {Object.keys(checkboxStates).filter(key => checkboxStates[key] === true).length} Tasks ?</h5>
              </div>
              <div className='modal-body '>
                <button className='btn btn-success btn-sm me-2 ' data-bs-dismiss="modal" onClick={(event) => { deleteTasks(event) }}>YES</button>
                <button className='btn btn-danger btn-sm  ' data-bs-dismiss="modal" aria-label="Close">NO</button>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}
