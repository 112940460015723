import { toast } from "react-toastify";

export default new (class UserPreferences {
  constructor() {
    this.KEY_TASKS_LIVE_UPDATES = "tasks.liveUpdates";
    this.KEY_LOGS_LIVE_UPDATES = "logs.liveUpdates";
    this.KEY_LICENSE = "license";
    this.KEY_SERVER_PASSWORD = "server.pwd";
    this.KEY_SERVER_URL = "server.url";

    this.KEY_LAST_PROFILE_SORT_INDEX = "profiles.lastSortIndex";
    this.KEY_LAST_PROFILE_SORT_ORDER = "profiles.lastSortOrder";

    this.KEY_LAST_TASKS_SORT_INDEX = "tasks.lastSortIndex";
    this.KEY_LAST_TASKS_SORT_ORDER = "tasks.lastSortOrder";

    this.DEFAULT_SERVER_URL = "http://localhost:5050";
  }

  saveProfileLastSort(selectedColumn, sortDirection) {
    localStorage.setItem(
      this.KEY_LAST_PROFILE_SORT_INDEX,
      String(selectedColumn.id)
    );
    localStorage.setItem(
      this.KEY_LAST_PROFILE_SORT_ORDER,
      Boolean(sortDirection === "asc").toString()
    );
  }

  saveTasksLastSort(selectedColumn, sortDirection) {
    localStorage.setItem(
      this.KEY_LAST_TASKS_SORT_INDEX,
      String(selectedColumn.id)
    );
    localStorage.setItem(
      this.KEY_LAST_TASKS_SORT_ORDER,
      Boolean(sortDirection === "asc").toString()
    );
  }

  getProfileLastSortIndex() {
    let data = localStorage.getItem(this.KEY_LAST_PROFILE_SORT_INDEX);
    if (data !== null && !isNaN(data)) {
      return Number(data);
    }
    return 1;
  }

  getProfileLastSortOrder() {
    let data = localStorage.getItem(this.KEY_LAST_PROFILE_SORT_ORDER);
    if (data !== null) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return true;
      }
    }
    return true;
  }

  getTasksLastSortIndex() {
    let data = localStorage.getItem(this.KEY_LAST_TASKS_SORT_INDEX);
    if (data !== null && !isNaN(data)) {
      return Number(data);
    }
    return 1;
  }

  getTasksLastSortOrder() {
    let data = localStorage.getItem(this.KEY_LAST_TASKS_SORT_ORDER);
    if (data !== null) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return true;
      }
    }
    return true;
  }

  enableTasksLiveUpdate() {
    localStorage.setItem(this.KEY_TASKS_LIVE_UPDATES, "on");
  }

  disableTasksLiveUpdate() {
    localStorage.setItem(this.KEY_TASKS_LIVE_UPDATES, "off");
  }

  haveTasksLiveUpdate() {
    return localStorage.getItem(this.KEY_TASKS_LIVE_UPDATES) === "on";
  }

  haveLogsLiveUpdate() {
    return localStorage.getItem(this.KEY_LOGS_LIVE_UPDATES) === "on";
  }

  setServerPassword(password) {
    localStorage.setItem(this.KEY_SERVER_PASSWORD, password);
  }

  getServerPassword() {
    return localStorage.getItem(this.KEY_SERVER_PASSWORD);
  }

  getServerUrl() {
    return localStorage.getItem(this.KEY_SERVER_URL) || this.DEFAULT_SERVER_URL;
  }

  setServerUrl(url) {
    if (!url) {
      url = this.DEFAULT_SERVER_URL;
    }
    localStorage.setItem(this.KEY_SERVER_URL, url);
  }

  setDefaultProfile(profileName, retailer) {
    if (!retailer || !profileName) {
      return;
    }

    localStorage.setItem("defaultProfile." + retailer, profileName);
  }

  getDefaultProfile(retailer) {
    if (!retailer) {
      return null;
    }
    return localStorage.getItem("defaultProfile." + retailer) || null;
  }

  isDefaultProfile(profileLabel, retailer) {
    return this.getDefaultProfile(retailer) === profileLabel;
  }

  removeDefaultProfile(profileLabel, retailer) {
    localStorage.removeItem("defaultProfile." + retailer);
  }

  setDefaultProxyPool(proxypoolName) {
    if (!proxypoolName) {
      return;
    }

    localStorage.setItem("defaultProxyPool", proxypoolName);
  }

  getDefaultProxyPool() {
    return localStorage.getItem("defaultProxyPool") || null;
  }

  isDefaultProxyPool(proxypoolName) {
    return this.getDefaultProxyPool() === proxypoolName;
  }

  removeDefaultProxyPool() {
    localStorage.removeItem("defaultProxyPool");
  }

  showToast() {
    toast("La fonctionnalité arrive bientôt!");
  }
})();
